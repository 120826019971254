import InputMask from 'react-input-mask';

export const InputPhone = ({ id, name, label, onChange, value, placeholder, disabled }) => {
    return (
        <div>
            <label htmlFor={name} className="block text-md font-semibold text-gray-900 leading-6 ml-1">
                {label}
            </label>
            <div className='mt-2'>
                <InputMask
                    className='block w-full focus:bg-indigo-50 rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    id={id}
                    name={name}
                    mask="(999) 999 9999"
                    maskPlaceholder=""
                    type="tel"
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};