// import { useNavigate } from 'react-router-dom';
import {
	optionsStart,
	optionsCoordinadorsSuccess,
	optionsLanguagesSuccess,
	optionsColorsSuccess,
	optionsLevelsSuccess,
	optionsFailure,
	optionsTeachersSuccess,
	optionsAccountsBankSuccess,
	optionsCourseListSuccess,
	optionsCourseListOpenByIdUserSuccess,
	optionsCourseIdentifiersSuccess,
	optionsAdminUserSuccess
} from '../reducers/options';

import axios from '../../api/axios';

export const optionsCoordinadors = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/coordinadors`);

        dispatch(optionsCoordinadorsSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsLanguages = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/languages`);

        dispatch(optionsLanguagesSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsLanguagesMulti = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/languagesMulti`);

        dispatch(optionsLanguagesSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsColors = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/colors`);

        dispatch(optionsColorsSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsLevels = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/levels`);

        dispatch(optionsLevelsSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsAllTeachers = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/teachers`);

        dispatch(optionsTeachersSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsAllAccountsBank = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/accountsBank`);

        dispatch(optionsAccountsBankSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsAllCourseList = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/coursesList`);

        dispatch(optionsCourseListSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsAllCourseListOpenByIdUser = (userId) => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/coursesListOpenByIdUser/${userId}`);

        dispatch(optionsCourseListOpenByIdUserSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsAllCourseIdentifier = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/allCourseIdentifier`);

        dispatch(optionsCourseIdentifiersSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

export const optionsAllAdminUser = () => async (dispatch) => {
	try {
        
		dispatch(optionsStart());

		const response = await axios.get(`/options/allAdminUser`);

        dispatch(optionsAdminUserSuccess(response.data));

	} catch (error) {
		
        dispatch(optionsFailure());

	}
};

