import { useDispatch, useSelector } from "react-redux";
import { Disclosure } from '@headlessui/react';
import { ImagenUser } from './ImagenUser';

import { logoutUser } from '../../redux/actions/user';

import { navigation, userNavigation } from '../../static/data';
import { NavLink } from "react-router-dom";
import { capitalizarPalabras } from "../../common/upperCaseWord";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const DropDownMovilMenu = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    const { firstName, lastName, email, avatarUrl } = user;

    const handleLogOut = () => {
        dispatch(logoutUser())
    }

    return (
        <Disclosure.Panel defaultChecked className="md:hidden" >
            {({ close }) => (
                <>
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                        {navigation.map((item) => (
                            item.access.includes(user.typeUser) && (
                                <NavLink
                                    to={item.href}
                                    key={item.name}
                                    onClick={() => close()}
                                    className={
                                        classNames(
                                            item.current
                                                ? 'bg-gray-950 text-white'
                                                : 'text-gray-950 hover:bg-gray-50 hover:text-indigo-600',
                                            'block rounded-md px-3 py-2 text-base font-medium'
                                        )}
                                >
                                    {item.name}
                                </NavLink>

                            )
                        ))}
                    </div>
                    <div className=" border-t border-gray-950/5 pb-3 pt-4">
                        <div className="flex items-center px-5">
                            {/* IMAGE USER */}
                            <ImagenUser url={avatarUrl} />
                            <div className="ml-3">
                                <div className="text-base font-medium leading-none text-gray-950">
                                    {capitalizarPalabras(`${firstName} ${lastName}`)}
                                </div>
                                <div className="text-sm font-medium leading-none text-indigo-600 mt-2">
                                    {email}
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 space-y-1 px-2">
                            <>
                                {userNavigation.map((item) => (
                                    item.access.includes(user.typeUser) && (
                                        (
                                            <NavLink
                                                to={item.href}
                                                key={item.name}
                                                onClick={() => close()}
                                                className={
                                                    classNames(
                                                        item.current
                                                            ? 'bg-gray-950 text-white'
                                                            : 'text-gray-950 hover:bg-gray-50 hover:text-indigo-600',
                                                        'block rounded-md px-3 py-2 text-base font-medium'
                                                    )}
                                            >
                                                {item.name}
                                            </NavLink>
                                        )
                                    )))}
                                <Disclosure.Button
                                    // key={item.name}
                                    onClick={handleLogOut}
                                    as="a"
                                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-950 hover:bg-gray-50 hover:text-indigo-600">
                                    Cerrar Sesión
                                </Disclosure.Button>
                            </>
                        </div>
                    </div>
                </>
            )}
        </Disclosure.Panel>
    )
}
