import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import { PublicLayout } from '../layout/public/PublicLayout';

export const PublicRoute = ({ children }) => {
    const token = localStorage.getItem('token');

    const { loading } = useSelector((state) => state.user);

    if (!loading) {
        if (token) {
            return <Navigate to='/' />;
        }
        return (
            <PublicLayout>
                {children}
            </PublicLayout>
        );
    }
};

