import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { getUserById, updateProfileUser } from '../../redux/actions/users';

import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';
import { InputText } from '../../components/inputs/InputText';
import { Title } from '../../components/Title';
import { InputPhone } from '../../components/inputPhone/InputPhone';
import { InputDateWithIcon } from '../../components/inputDateWithIcon/InputDateWithIcon';
import { ComboBox } from '../../components/comboBox/ComboBox';
import { validateEmailTax } from '../../common/validations';
import { ModalConfirmChanges } from './ModalConfirmChanges';
import { ChangeAvatarModal } from './ChangeAvatarModal';

import { locationState, levelEducation } from '../../static/data';

export const EditProfile = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { userId } = params;

    const { userSelected } = useSelector((state) => state.users);
    const cancelSaveChangesRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState();
    const [findLocation, setFindLocation] = useState('');
    const [findEducation, setFindEducation] = useState('');
    const [loading, setLoading] = useState(false);
    const [openChangeAvatarModal, setOpenChangeAvatarModal] = useState(false);
    const [openModalSaveChanges, setModalOpenSaveChanges] = useState(false);
    const [formData, setFormData] = useState({
        avatarUrl: '',
        firstName: '',
        secondName: '',
        lastName: '',
        secondSurname: '',
        location: '',
        phone: '',
        dateBirth: '',
        education: '',
        subscriptionPayment: '',
        emailTax: '',
        rfc: '',
        businessName: '',
        taxRegime: ''
    });

    useEffect(() => {
        dispatch(getUserById(userId))
    }, [userId, dispatch]);

    useEffect(() => {
        buildInitialState();
    }, [userSelected]);

    const buildInitialState = () => {
        setFormData((prevData) => ({
            ...prevData,
            avatarUrl: avatarUrl || '',
            firstName: firstName || '',
            secondName: secondName || '',
            lastName: lastName || '',
            secondSurname: secondSurname || '',
            location: { value: location, description: location } || '',
            phone: phone || '',
            dateBirth: dateBirth || undefined,
            education: { value: education, description: education } || '',
            subscriptionPayment: subscriptionPayment || 0,
            emailTax: emailTax || '',
            rfc: rfc || '',
            businessName: businessName || '',
            taxRegime: taxRegime || ''
        }));

        setSelectedDate(dateBirth);
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const filteredLocations = findLocation === ''
        ? locationState
        : locationState.filter((location) =>
            location.description
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findLocation.toLowerCase().replace(/\s+/g, ''))
        );

    const filteredEducations = findEducation === ''
        ? levelEducation
        : levelEducation.filter((grade) =>
            grade.description
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findEducation.toLowerCase().replace(/\s+/g, ''))
        );

    const {
        avatarUrl, firstName, secondName,
        lastName, secondSurname,
        phone, dateBirth, location, typeUser,
        education, subscriptionPayment,
        emailTax, rfc, businessName, taxRegime
    } = userSelected;

    const validateForm = (updateState) => {

        if (updateState.emailTax !== '') {
            let emailTaxValid = validateEmailTax(updateState.emailTax);
            if (!emailTaxValid) {
                toast.error('Correo electronico fiscal invalido');
                return false;
            }
            if (updateState.firstName === '') {
                toast.error('El nombre es obligatorio');
                return false;
            }
            if (updateState.lastName === '') {
                toast.error('El el primer apellido es obligatorio');
                return false;
            }
        }
        return true;

    }

    const handleSaveChangesProfile = () => {
        const updateState = {
            ...formData,
            dateBirth: selectedDate,
            location: formData.location.description,
            education: formData.education.description,
            firstName: formData.firstName ? formData.firstName.toLowerCase() : '',
            secondName: formData.secondName ? formData.secondName.toLowerCase() : '',
            lastName: formData.lastName ? formData.lastName.toLowerCase() : '',
            secondSurname: formData.secondSurname ? formData.secondSurname.toLowerCase() : '',
            rfc: formData.rfc ? formData.rfc.toLowerCase() : '',
            businessName: formData.businessName ? formData.businessName.toLowerCase() : '',
            subscriptionPayment: formData.subscriptionPayment ? parseFloat(formData.subscriptionPayment) : 0
        };
        const valid = validateForm(updateState);
        if (valid) {
            setLoading(true);
            dispatch(updateProfileUser(updateState, userId))
                .then((response) => {
                    if (response.status === 201) {
                        toast.success(response.message);
                        setModalOpenSaveChanges(false);
                        setLoading(false);
                        navigate('/mi-perfil');
                    } else {
                        setLoading(false);
                        toast.error(response.message);
                    }
                });
        } else {
            setModalOpenSaveChanges(false);

        }
    }

    return (
        <ContainerFull>
            <Heading
                title={`Modificar mi perfil`}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4 lg:p-8'>
                        <Title title={'Moficar datos personales'} />
                        <div className='w-full md:w-[50%] grid grid-cols-1 gap-4'>
                            <div>
                                <InputText
                                    id={'firstName'}
                                    name={'firstName'}
                                    type={'text'}
                                    label={'Nombre'}
                                    onChange={(e) => onChange(e)}
                                    value={formData.firstName}
                                    placeholder={'Ingresa tu nombre'}
                                    disabled={false}
                                />
                            </div>
                            <div>
                                <InputText
                                    id={'secondName'}
                                    name={'secondName'}
                                    type={'text'}
                                    label={'Otros nombres'}
                                    onChange={(e) => onChange(e)}
                                    value={formData.secondName}
                                    placeholder={'Ingresa tus otros nombres'}
                                    disabled={false}
                                />
                            </div>
                            <div>
                                <InputText
                                    id={'lastName'}
                                    name={'lastName'}
                                    type={'text'}
                                    label={'Primer apellido'}
                                    onChange={(e) => onChange(e)}
                                    value={formData.lastName}
                                    placeholder={'Ingresa tu primer apellido'}
                                    disabled={false}
                                />
                            </div>
                            <div>
                                <InputText
                                    id={'secondSurname'}
                                    name={'secondSurname'}
                                    type={'text'}
                                    label={'Segundo apellido'}
                                    onChange={(e) => onChange(e)}
                                    value={formData.secondSurname}
                                    placeholder={'Ingresa tu segundo apellido'}
                                    disabled={false}
                                />
                            </div>
                            <div>
                                <InputPhone
                                    id={'phone'}
                                    name={'phone'}
                                    type={'text'}
                                    label={'Telefono'}
                                    onChange={(e) => onChange(e)}
                                    value={formData.phone}
                                    placeholder={'Ingresa tu numero de telefono a 10 digitos'}
                                    disabled={false}
                                />
                            </div>
                            <div>
                                <h3 className="text-md font-semibold text-gray-900 mt-4">Nivel educativo</h3>
                                <ComboBox
                                    filterData={filteredEducations}
                                    query={findEducation}
                                    setQuery={setFindEducation}
                                    selected={formData}
                                    setSelected={setFormData}
                                    placeholder='Nivel educativo'
                                    property='education'
                                />
                            </div>
                            <div>
                                <h3 className="text-md font-semibold text-gray-900 mt-4">Lugar de residencia</h3>
                                <ComboBox
                                    filterData={filteredLocations}
                                    query={findLocation}
                                    setQuery={setFindLocation}
                                    selected={formData}
                                    setSelected={setFormData}
                                    placeholder='Lugar de residencia'
                                    property='location'
                                />
                            </div>
                            {
                                typeUser === 'estudiante' && (
                                    <div>
                                        <InputText
                                            id={'subscriptionPayment'}
                                            name={'subscriptionPayment'}
                                            type={'text'}
                                            label={'Monto de suscripcion'}
                                            onChange={(e) => onChange(e)}
                                            value={formData.subscriptionPayment}
                                            placeholder={'Ingresa el monto de tu suscripcion'}
                                            disabled={false}
                                        />
                                    </div>

                                )
                            }
                            <div>
                                <InputDateWithIcon
                                    id={'dateBirth'}
                                    label={'Fehca de nacimiento'}
                                    onChange={setSelectedDate}
                                    value={selectedDate}
                                    placeholder={'Ingresa tu fecha de nacimiento'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-col lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4 lg:p-8'>
                        <div>
                            <Title title={'Seleccionar otro avatar'} />
                            <div className='w-full flex flex-col md:w-[75%] mx-auto justify-center items-center border-dashed border-indigo-950/10 border-[2px] rounded-md py-10'>
                                <img
                                    className="h-32 w-32 rounded-full border-[5px] border-gray-200"
                                    alt="Imagen de usuario"
                                    src={formData.avatarUrl}
                                />
                                <button
                                    onClick={() => setOpenChangeAvatarModal(true)}
                                    type='button'
                                    className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 w-[80%] lg:w-[40%] mt-8'
                                >Selecionar un nuevo avatar</button>
                            </div>
                        </div>
                        {/* {
                            typeUser === 'estudiante' && ( */}
                        <div className='border-t-2 border-gray-100 mt-10 py-6'>
                            <Title title={'Datos fiscales'} />

                            <div className='w-full md:w-[50%] grid grid-cols-1 gap-4'>
                                <div>
                                    <InputText
                                        id={'emailTax'}
                                        name={'emailTax'}
                                        type={'email'}
                                        label={'Correo electronico para facturacion'}
                                        onChange={(e) => onChange(e)}
                                        value={formData.emailTax}
                                        placeholder={'Ingresa tu correo electronico para facturacion'}
                                        disabled={false}
                                    />
                                </div>
                                <div>
                                    <InputText
                                        id={'rfc'}
                                        name={'rfc'}
                                        type={'text'}
                                        label={'RFC'}
                                        onChange={(e) => onChange(e)}
                                        value={formData.rfc}
                                        placeholder={'Ingresa tu RFC'}
                                        disabled={false}
                                    />
                                </div>
                                <div>
                                    <InputText
                                        id={'businessName'}
                                        name={'businessName'}
                                        type={'text'}
                                        label={'Razon social'}
                                        onChange={(e) => onChange(e)}
                                        value={formData.businessName}
                                        placeholder={'Ingresa tu razon social'}
                                        disabled={false}
                                    />
                                </div>
                                <div>
                                    <InputText
                                        id={'taxRegime'}
                                        name={'taxRegime'}
                                        type={'text'}
                                        label={'Regimen fiscal'}
                                        onChange={(e) => onChange(e)}
                                        value={formData.taxRegime}
                                        placeholder={'Ingresa tu regimen fiscal'}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* )
                        } */}
                        <div className='w-full flex justify-end gap-4 mt-10'>
                            <button
                                onClick={() => navigate('/mi-perfil')}
                                type='button'
                                className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-red-500 hover:bg-red-600 w-[100%] lg:w-[25%]'
                            >Cancelar</button>
                            {
                                
                            }
                            <button
                                onClick={() => setModalOpenSaveChanges(true)}
                                type='button'
                                className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 hover:bg-indigo-700 w-[100%] lg:w-[25%]'
                            >Guardar</button>
                        </div>

                    </div>
                </div>

            </Wrapper>
            <ModalConfirmChanges
                open={openModalSaveChanges}
                setOpen={setModalOpenSaveChanges}
                cancelButtonRef={cancelSaveChangesRef}
                confirmAction={handleSaveChangesProfile}
                title={'Confirmacion de guardado'}
                message={'Estas seguro de guardar los cambios?'}
                labelButonConfirm={'Guardar cambios'}
                action={'Guardar'}
                loading={loading}
            />
            <ChangeAvatarModal
                open={openChangeAvatarModal}
                setOpen={setOpenChangeAvatarModal}
                state={formData}
                setState={setFormData}
            />
        </ContainerFull >
    )
}
