import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAllPaymentByUserId } from '../../redux/actions/payment';


export const PaymentHistory = ({ action = false }) => {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { _id: userId } = user;
    const { paymentsByUser } = useSelector((state) => state.payment);


    useEffect(() => {
        dispatch(getAllPaymentByUserId(userId))
    }, [])

    return (
        <>
            {
                paymentsByUser.length
                    ? paymentsByUser.map(({ id, paymentType, validatedBy, status, amount, formatCreatedAt }) => (
                        <div
                            className='flex flex-col border-[1px] bg-white border-gray-200 rounded-lg p-4 my-4'
                            key={id}>
                            <div className='w-full flex flex-col'>
                                <div>
                                    <span className='text-[12px] text-gray-600 tracking-wide capitalize'>Fecha de registro: </span>
                                    <span className='text-[12px] text-gray-900 font-semibold tracking-wide capitalize'>{formatCreatedAt}</span>
                                </div>
                                <div>
                                    <span className='text-[12px] text-gray-600 tracking-wide capitalize'>Estatus del pago: </span>
                                    <span className='text-[12px] text-gray-900 font-semibold tracking-wide capitalize'>{status}</span>
                                </div>
                                <div>
                                    <span className='text-[12px] text-gray-600 tracking-wide capitalize'>Monto del pago: </span>
                                    <span className='text-[12px] text-gray-900 font-semibold tracking-wide capitalize'>{amount ? `$ ${amount}` : `$ 0`}</span>
                                </div>
                                <div>
                                    <span className='text-[12px] text-gray-600 tracking-wide capitalize'>Tipo de pago: </span>
                                    <span className='text-[12px] text-gray-900 font-semibold tracking-wide capitalize'>{paymentType}</span>
                                </div>
                                <div>
                                    <span className='text-[12px] text-gray-600 tracking-wide capitalize'>Pago validado por: </span>
                                    <span className='text-[12px] text-gray-900 font-semibold tracking-wide capitalize'>{validatedBy ? validatedBy : '-'}</span>
                                </div>
                            </div>
                            {
                                action && (
                                    <div className='w-full'>
                                        <div className='w-full flex justify-end'>
                                            {
                                                status === 'validado'
                                                    ? <Link
                                                        className='rounded-lg transition py-2 px-3  font-semibold text-md text-white text-center bg-sky-600 hover:bg-sky-800'
                                                        to={`/detalle-pago/${id}`}
                                                    >
                                                        Detalle del pago
                                                    </Link>
                                                    : <Link
                                                        className='rounded-lg transition py-2 px-3  font-semibold text-md text-white text-center bg-orange-500 hover:bg-orange-700'
                                                        to={`/modificar-pago/${id}`}
                                                    >
                                                        Modificar pago
                                                    </Link>
                                            }
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    ))
                    : <p className='p-4 text-gray-500'>No hay registro de pagos</p>
            }
        </>
    )
}
