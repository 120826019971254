import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-hot-toast';

import { Modal } from '../../components/modal/Modal';
import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { InputTextArea } from '../../components/inputTextArea/InputTextArea';
import { declinePayment } from '../../redux/actions/payment';

export const ModalDeclinePayment = ({ open, setOpen, paymentId }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cancelButtonRef = useRef(null);
	const { user } = useSelector((state) => state.user);
	const { paymentSelected } = useSelector((state) => state.payment);
	const { _id: userId } = user;
	const { preRegisterSelected } = useSelector((state) => state.payment);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		paymentId: paymentId,
		userId: '',
		email: '',
		message: ''
	});

	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			message: ''
		}));
		setLoading(false);
	}, [open]);

	const onChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	}

	const validateForm = (updateState) => {
		if (updateState.message === '') {
			toast.error('Ingresa un mensaje para el candidato.');
			return false;
		}
		return true;
	}

	const handleDeclinePayment = async () => {
		setFormData((prevData) => ({
			...prevData,
			userId: userId,
			email: paymentSelected.user.email
		}));
		const updateState = {
			...formData,
			userId: userId,
			email: paymentSelected.user.email
		}
		const valid = validateForm(updateState);
		if (valid) {
			setLoading(true);
			dispatch(declinePayment(updateState))
				.then((result) => {
					if (result.status === 200) {
						setOpen(false);
						setFormData((prevData) => ({
							...prevData,
							message: ''
						}));
						setLoading(false);
						navigate('/pagos');
					} else {
						toast.error(result.message);
						setOpen(false);
						setLoading(false);
					}
				});
		}

	}

	return (
		<Modal open={open} setOpen={setOpen} cancelButtonRef={cancelButtonRef}>
			<div className="bg-white p-4">
				<div className="flex flex-col">

					<Dialog.Title as="h3" className="text-center text-lg font-semibold leading-6 text-gray-950">
						Rechazar pago
					</Dialog.Title>
					<div className="mt-6">
						<p className="text-sm text-center text-gray-600">
							Explicale al estudiante por que estas rechazando su pago. Por favor, se lo más claro posible. Este mensaje será enviado al estudiante.
						</p>

					</div>
					<div className='w-[90%] mx-auto grid grid-cols-1 gap-4 m-8'>
						<div>
							<InputTextArea
								id={'message'}
								name={'message'}
								type={'message'}
								label={'Explique al estudiante por que esta rechanzado su pago.'}
								onChange={(e) => onChange(e)}
								value={formData.message}
								placeholder={'Ingresa tu mensaje...'}
							/>
						</div>
						<div className='mt-[20px]'>
							<button
								type='button'
								disabled={loading}
								className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 w-full'
								onClick={() => handleDeclinePayment()}
							>
								{loading
									? <ButtonLoader />
									: 'Enviar mensaje al estudiante'
								}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
