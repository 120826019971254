import React from 'react'
import { urlFlag } from '../../common/urlBase';
import { formatDate } from '../../common/formatDateText';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const InputCourseMulti = ({ coursesList, coursesSelected, setCoursesSelected }) => {
    // const [coursesSelected, setCoursesSelected] = useState([]);

    const handleCheckboxChange = (event) => {
        const checkboxValue = event.target.value;
        // Verificar si el checkbox está seleccionado
        if (event.target.checked) {
            // Agregar el valor al estado
            setCoursesSelected([...coursesSelected, checkboxValue]);
        } else {
            // Remover el valor del estado
            setCoursesSelected(coursesSelected.filter(value => value !== checkboxValue));
        }
    };

    return (
        <>
            <ul className="w-full flex flex-col gap-4">
                {coursesList.map(({ _id, identifier, language, level, color, path, fromDate, toDate, limitMembers, hours, days, teacher, status }) => (
                    <li key={_id}>
                        <input
                            type="checkbox"
                            id={_id}
                            value={_id}
                            className="hidden peer"
                            onChange={handleCheckboxChange}
                            checked={coursesSelected.includes(_id)}
                        />
                        <label for={_id} className="inline-flex items-center justify-between w-full p-2 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-indigo-600 peer-checked:border-[3px] hover:ring-indigo-500  ">
                            <div className='w-full p-2'>

                                <div className='w-full flex flex-col'>
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <p className='text-[.8rem] md:text-[1rem] text-gray-900 font-bold tracking-wide uppercase'>{language ? `Curso de ${language}` : '-'}</p>
                                            <div className="flex items-center">
                                                <span className={classNames(
                                                    color,
                                                    'inline-block w-[12px] md:h-[12px] rounded-lg mr-2'
                                                )}></span>
                                                <p className='text-[.8rem] text-indigo-700 font-bold tracking-wide uppercase'>{level}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {path
                                                ? (
                                                    <img className="w-[2rem]" src={`${urlFlag}${path}`} alt="flag" />
                                                )
                                                : null}
                                        </div>
                                    </div>


                                    <div className='w-full flex justify-center items-center md:invisible mt-4 md:mt-0'>
                                        <div
                                            className={classNames(
                                                status === 'abierto'
                                                    ? 'text-sky-700 ring-sky-700 shadow-sky-700'
                                                    : status === 'en curso' ? 'text-lime-600 ring-lime-600 shadow-lime-600'
                                                        : status === 'completado' ? 'text-indigo-600 ring-indigo-600 shadow-indigo-600'
                                                            : status === 'cancelado' ? 'text-gray-600 ring-gray-600 shadow-gray-600'
                                                                : status === 'finalizado' ? 'text-red-500 ring-red-500 shadow-red-500' : null,
                                                'border-none ring-2  shadow-sm py-0 px-1 rounded-sm uppercase font-bold'
                                            )}>
                                            <p className="text-[.8rem]">{status}</p>
                                        </div>
                                    </div>


                                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 md:mt-0">
                                        <div className='w-full flex flex-col md:flex-row justify-center items-center md:justify-start'>
                                            {/* <p className="font-normal text-[.8rem] text-gray-600 leading-6">Profesor del curso: </p>
                                            <p className="text-slate-800 font-semibold text-[.9rem] tracking-wide mt-2 md:mt-0 md:ml-2 capitalize">{teacher !== '' ? teacher : '-'}</p> */}
                                        </div>
                                        <div className='hidden md:w-full md:flex md:flex-col md:justify-start md:items-end'>
                                            <div
                                                className={classNames(
                                                    status === 'abierto'
                                                        ? 'text-sky-700 ring-sky-700 shadow-sky-700'
                                                        : status === 'en curso' ? 'text-lime-600 ring-lime-600 shadow-lime-600'
                                                            : status === 'completado' ? 'text-indigo-600 ring-indigo-600 shadow-indigo-600'
                                                                : status === 'cancelado' ? 'text-gray-600 ring-gray-600 shadow-gray-600'
                                                                    : status === 'finalizado' ? 'text-red-500 ring-red-500 shadow-red-500' : null,
                                                    'border-none ring-2  shadow-sm py-0 px-1 rounded-sm uppercase font-bold'
                                                )}>
                                                <p className="text-[.8rem]">{status}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full flex flex-col p-2 md:p-6'>
                                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-0 md:mt-4">
                                        <div className='w-full flex flex-col justify-center items-center'>
                                            <p className="font-normal text-sm text-gray-600 leading-6">Identificador de curso</p>
                                            <p className="text-slate-800 font-semibold text-md tracking-wide uppercase">{identifier !== '' ? `[ ${identifier} ]` : '-'}</p>
                                        </div>
                                        <div className='w-full flex flex-col justify-center items-center'>
                                            <p className="font-normal text-sm text-gray-600 leading-6">Profesor del curso</p>
                                            <p className="text-slate-800 font-semibold text-md tracking-wide capitalize">{teacher !== '' ? teacher : '-'}</p>
                                        </div>
                                        <div className='w-full flex flex-col justify-center items-center'>
                                            <p className="font-normal text-sm text-gray-600 leading-6">Fecha de inicio de curso</p>
                                            <p className="text-slate-800 font-semibold text-md tracking-wide capitalize">{fromDate !== '' ? formatDate(fromDate) : '-'}</p>
                                        </div>
                                        <div className='w-full flex flex-col justify-center items-center'>
                                            <p className="font-normal text-sm text-gray-600 leading-6">Fecha de fin de curso</p>
                                            <p className="text-slate-800 font-semibold text-md tracking-wide capitalize">{toDate !== '' ? formatDate(toDate) : '-'}</p>
                                        </div>

                                        <div className="w-full flex flex-col justify-center items-center">
                                            <p className="font-normal text-sm text-gray-600 leading-6 mb-2">Horario</p>
                                            <p className="text-slate-800 font-semibold text-md tracking-wide">{
                                                hours !== undefined
                                                    ? `${hours.length > 0 ? hours[0]?.time : ''} - ${hours.length > 0 ? hours[hours?.length - 1]?.time : ''}`
                                                    : 'No hay tiempo selecionado'
                                            }</p>
                                        </div>
                                        <div className="w-full flex flex-col justify-center items-center">
                                            <p className="font-normal text-sm text-gray-600 leading-6 mb-2">Lugares disponibles</p>
                                            <p className="text-slate-800 font-semibold text-md tracking-wide">{limitMembers}</p>
                                        </div>

                                    </div>
                                    <div className="w-full md:w-[80%] mx-auto flex flex-col justify-center items-center min-h-[130px]">
                                        <p className="font-normal text-sm text-gray-600 leading-6 mb-4">Dias de clase</p>
                                        <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-4">
                                            {days !== '' ? days?.map(day => <div key={day.id} className="bg-slate-100 text-slate-800 font-semibold text-md text-center px-2 md:p-2 tracking-wide rounded-md capitalize">{day.day}</div>) : 'Hay dias selecionados'}
                                        </div>
                                    </div>

                                    <div className="w-full grid grid-cols-2 gap-4">

                                    </div>
                                </div>
                            </div>
                        </label>
                    </li>

                ))}
            </ul>

        </>
    )
}
