import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import {
    ArrowRightOnRectangleIcon
} from '@heroicons/react/24/outline';
import { userMenu } from '../../static/data';
import { MenuItem } from './MenuItem';
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from '../../redux/actions/user';

export const UserMenu = () => {

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);

    const handleLogOut = () => {
        dispatch(logoutUser())
    }

    return (
        <div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <div
                    className="
                    absolute 
                    rounded-3xl
                    shadow-md
                    bg-white
                    overflow-hidden 
                    right-2
                    top-[56px] 
                    text-sm
                    ring-2
                    ring-indigo-900/10
                    shadow-indigo-100
                ">
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-whitetext-sm leading-6">
                        <div className="p-4">
                            {userMenu.map((item) => (
                                item.access.includes(user.typeUser) && (
                                    (user.roles === 'user' && item.name === 'Configuracion')
                                        ? null
                                        : (
                                            <MenuItem
                                                key={item.name}
                                                name={item.name}
                                                description={item.description}
                                                href={item.href}
                                                icon={item.icon}
                                            />
                                        )
                                )
                            ))}
                            <hr className='mt-4' />

                            <div
                                onClick={handleLogOut}
                                className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 mt-5"
                            >
                                <div
                                    className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
                                >
                                    <ArrowRightOnRectangleIcon
                                        aria-hidden="true"
                                        className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                                    />
                                </div>
                                <div className="flex items-center">
                                    <span className="font-semibold text">
                                        Cerrar Sesión
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Transition>

        </div>
    )
}
