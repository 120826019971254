import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { DataGrid, esES } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { ContainerFull } from '../../components/ContainerFull';
import { Wrapper } from '../../components/Wrapper';
import { Heading } from '../../components/Heading';
import { Button } from '../../components/buttons/Button';
import { capitalizarPalabras } from '../../common/upperCaseWord';
import { ModalDeleteStudentList } from './components/ModalDeleteStudentList';
import { ModalAddStudentToCourse } from './components/ModalAddStudentToCourse';

import {
	deleteStudentFromCourse,
	getCourseById,
	getListStudentsByIdCourse,
	getListStudentsNotInCourse,
	requestDeleteStudentFromCourse
} from '../../redux/actions/course';

import { exportToExcel } from '../../common/importDataToExcel';
import { formatDate, validateSubscription } from '../../common/formatDateText';
// import useIsMobile from '../../hooks/useIsMobile';

export const CourseEditListStudents = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const idCourse = params?.idCourse;

	const [openAddStudentToCourseModal, setOpenAddStudentToCourseModal] = useState(false);
	const [openModalDeleteStudentList, setModalOpenDeleteStudentList] = useState(false);
	const [selectStudentDelete, setSelectStudentDelete] = useState(null);
	const [action, setAction] = useState('')
	const [loadingDeleteStudent, setLoadingDeleteStudent] = useState(false);
	const [searchText, setSearchText] = useState('');

	const cancelDeleteStudentListRef = useRef(null);

	// const isMobile = useIsMobile();

	const user = useSelector((state) => state.user);
	const { user: { roles, typeUser, _id: userId } } = user;

	const { courseListSelected, courseSelected } = useSelector((state) => state.course);

	useEffect(() => {
		if (idCourse) {
			dispatch(getCourseById(idCourse));
			dispatch(getListStudentsByIdCourse(idCourse));
		}
	}, [idCourse, dispatch]);

	useEffect(() => {
		if (user) {
			setAction(typeUser === 'control escolar' ? 'request' : 'execute')
		}
	}, [user]);


	useEffect(() => {
		dispatch(getListStudentsNotInCourse(idCourse));
	}, [idCourse, dispatch]);


	useEffect(() => {
		if (openModalDeleteStudentList === false) {
			setSelectStudentDelete(null)
		}
	}, [openModalDeleteStudentList]);

	const handleDeleteStudentList = (student) => {
		setSelectStudentDelete(student);
		setModalOpenDeleteStudentList(true);
	}

	const handleConfirmDeleteStudentList = () => {
		setLoadingDeleteStudent(true);
		if (action === 'request') {
			dispatch(requestDeleteStudentFromCourse(idCourse, selectStudentDelete.id, userId))
				.then((result) => {
					if (result.status === 200) {
						toast.success(result.message);
						setModalOpenDeleteStudentList(false);
						setSelectStudentDelete(null);
						// dispatch(getListStudentsByIdCourse(idCourse));
						// dispatch(getListStudentsNotInCourse(idCourse));
					} else {
						toast.error(result.message);
					}
					setLoadingDeleteStudent(false);
				});
		}

		if (action === 'execute') {
			dispatch(deleteStudentFromCourse(idCourse, selectStudentDelete.id))
				.then((result) => {
					if (result.status === 200) {
						toast.success(result.message);
						setModalOpenDeleteStudentList(false);
						setSelectStudentDelete(null);
						dispatch(getListStudentsByIdCourse(idCourse));
						dispatch(getListStudentsNotInCourse(idCourse));
					} else {
						toast.error(result.message);
					}
					setLoadingDeleteStudent(false);
				});
		}
	}

	const formatDataExcel = async (data) => {
		const formattedData = data.map(item => ({
			matricula: item.matricula,
			nombre: item.firstName,
			segundo_nombre: item.secondName,
			primer_apellido: item.lastName,
			segundo_apellido: item.secondSurname,
			correo_electronico: item.email,
			telefono: item.phone,
			fecha_vencimiento: formatDate(item.paymentDeadlineDate)
		}));
		return formattedData;
	}

	const handleImportData = async (data, name) => {
		const dataReady = await formatDataExcel(data);
		const response = exportToExcel(dataReady, name)
		response.data ? toast.error(response.message) : toast.success(response.message)
	}

	// const handleSearchChange = (event) => {
	// 	const searchText = event.target.value;
	// 	setSearchText(searchText);
	// };

	// const rows = courseListSelected;

	// const columns = [
	// 	{
	// 		field: 'matricula',
	// 		headerName: 'Matricula',
	// 		flex: 1,
	// 		renderCell: (params) => (
	// 			<p className='capitalize'>{params.value}</p>
	// 		),
	// 	},
	// 	{
	// 		field: 'firstName',
	// 		headerName: 'Nombre',
	// 		flex: 1,
	// 		renderCell: (params) => (
	// 			<p className='capitalize'>{params.value}</p>
	// 		),
	// 	},
	// 	{
	// 		field: 'lastName',
	// 		headerName: 'Apellido',
	// 		flex: 1,
	// 		renderCell: (params) => (
	// 			<p className='capitalize'>{params.value}</p>
	// 		),
	// 	},
	// 	{ field: 'email', headerName: 'Correo Electronico', flex: 1 },
	// 	{
	// 		field: 'phone',
	// 		headerName: 'Telefono',
	// 		flex: 1,
	// 		renderCell: (params) => (
	// 			<p>{params.value}</p>
	// 		),
	// 	},
	// 	{
	// 		field: 'paymentDeadlineDate',
	// 		headerName: 'Fecha de vencimiento',
	// 		flex: 1,
	// 		renderCell: (params) => (
	// 			<p className={`capitalize ${validateSubscription(params.value) ? 'text-green-600' : 'text-red-500'}`}>{formatDate(params.value)}</p>
	// 		),
	// 	},
	// 	{
	// 		field: 'action',
	// 		headerName: 'Acciones',
	// 		flex: 1,
	// 		sortable: false,
	// 		disableColumnMenu: true,
	// 		renderCell: (params) => (
	// 			<div className='w-full flex justify-between items-center gap-4'>
	// 				<button
	// 					className='w-[80%] flex justify-center mx-auto px-4 py-1.5 bg-red-600 uppercase text-slate-50 rounded hover:bg-red-800'
	// 					onClick={() => handleDeleteStudentList({ id: params.id, name: `${params.row.firstName} ${params.row.lastName}` })}
	// 				>
	// 					Eliminar
	// 				</button>
	// 				<Link
	// 					to={`/perfil/${params.id}`}
	// 					className="w-[80%] flex justify-center mx-auto px-4 py-1.5 bg-indigo-600 uppercase text-slate-50 rounded hover:bg-indigo-800"
	// 				>
	// 					Perfil
	// 				</Link>
	// 			</div>
	// 		),
	// 	},
	// ];


	// const filteredRows = rows.filter((row) =>
	// 	row.matricula?.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
	// 	row.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
	// 	row.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
	// 	row.email?.toLowerCase().includes(searchText.toLowerCase()) ||
	// 	row.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
	// 	formatDate(row.paymentDeadlineDate)?.toLowerCase().includes(searchText.toLowerCase())
	// );

	return (
		<ContainerFull>
			<Heading
				title={`Lista de estudiantes del [ ${courseSelected?.identifier ? courseSelected?.identifier.toUpperCase() : null} ]`}
				center={false}
			/>
			{
				roles === 'admin' && (
					<div className='w-full flex flex-col lg:flex-row lg:justify-end mt-4 lg:mt-0 gap-4'>
						<Button label={"Descargar lista de estudiantes"} onClick={() => handleImportData(courseListSelected, 'Lista_de_estudiantes.xlsx')} />
						<Button label={action === 'request' ? "Solicitar agregar a un nuevo estudiante" : "Agregar un nuevo estudiante"} onClick={() => setOpenAddStudentToCourseModal(true)} />
					</div>
				)
			}

			{/* {!isMobile && (
				<Wrapper>
					<input
						type="text"
						placeholder="Buscar un estudiante"
						value={searchText}
						onChange={handleSearchChange}
						className='block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' />
				</Wrapper>
			)} */}

			<Wrapper>
				{/* {
					!isMobile && (
						<div className='hidden md:flex'>
							<DataGrid
								rows={filteredRows}
								columns={columns}
								// onRowClick={handleRowClick}
								localeText={esES.components.MuiDataGrid.defaultProps.localeText}
								initialState={{
									pagination: {
										paginationModel: { pageSize: 15, page: 0, },
									},
								}}
								pageSizeOptions={[10, 15, 25]}
							/>
						</div>
					)
				} */}
				{/* {
					isMobile && ( */}
				{/* <div className='w-full grid grid-cols-1 md:hidden gap-4'> */}
				<div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
					{courseListSelected.length > 0
						? courseListSelected.map(({ id, avatarUrl = 'https://res.cloudinary.com/dax0v05jz/image/upload/v1708645791/uploads/hbzdzch5ldxw6pszwmej.png', matricula, firstName, lastName, email, phone, paymentDeadlineDate }) => (
							<div key={id} className='flex flex-col border-[1px] border-gray-200  rounded-md'>
								<div className='w-full flex justify-center my-8'>
									<img className="ring-[4px] ring-gray-200 h-24 w-24 rounded-full" src={avatarUrl} alt="Imagen de usuario" />
								</div>
								<div className='w-[75%] flex flex-col mx-auto items-start my-4 gap-1'>
									<p className='text-[.9rem] font-semibold text-gray-500'>Matricula</p>
									<p className='text-[1rem] font-semibold text-gray-900 ml-2'>{matricula}</p>
									<p className='text-[.9rem] font-semibold text-gray-500'>Nombre:</p>
									<p className='text-[1rem] font-semibold text-gray-900 ml-2'>{capitalizarPalabras(`${firstName} ${lastName}`)}</p>
									<p className='text-[.9rem] font-semibold text-gray-500'>Email:</p>
									<p className='text-[1rem] font-semibold text-gray-900 ml-2'>{email}</p>
									<p className='text-[.9rem] font-semibold text-gray-500'>Telefono:</p>
									<p className='text-[1rem] font-semibold text-gray-900 ml-2'>{phone}</p>
									<p className='text-[.9rem] font-semibold text-gray-500'>Fecha de vencimiento:</p>
									<p className='text-[1rem] font-semibold text-gray-900 ml-2'>{paymentDeadlineDate ? capitalizarPalabras(formatDate(paymentDeadlineDate)) : 'No hay registro'}</p>
								</div>

								{user.user?.roles === 'admin' && (
									<div className='w-full flex my-10 justify-around'>
										{/* <button
											className='bg-indigo-600 hover:bg-indigo-700 text-white rounded-md px-4 py-2.5'
											onClick={() => handleDeleteStudentList({ id, name: `${firstName} ${lastName}` })}
										>
											Perfil del estudiante
										</button> */}
										<Link
											to={`/perfil/${id}`}
											className="bg-indigo-600 hover:bg-indigo-700 text-white rounded-md px-4 py-2.5"
										>
											Perfil del estudiante
										</Link>
										{
											typeUser === 'control escolar' && (
												<button
													className='bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2.5'
													onClick={() => handleDeleteStudentList({ id, name: `${firstName} ${lastName}` })}
												>
													Solicitar baja de estudiante
												</button>
											)
										}
										{
											typeUser === 'director' && (
												<button
													className='bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2.5'
													onClick={() => handleDeleteStudentList({ id, name: `${firstName} ${lastName}` })}
												>
													Eliminar del curso
												</button>
											)
										}
									</div>
								)}
							</div>
						))
						: (
							<p className='text-gray-400'>No hay estudiantes en este curso.</p>
						)
					}
				</div>
				{/* )
				} */}

			</Wrapper>
			<ModalDeleteStudentList
				open={openModalDeleteStudentList}
				setOpen={setModalOpenDeleteStudentList}
				cancelButtonRef={cancelDeleteStudentListRef}
				confirmAction={handleConfirmDeleteStudentList}
				loadingDeleteStudent={loadingDeleteStudent}
				title={
					action === 'request'
						? 'Solicitud para eliminar a estudiante del curso'
						: 'Eliminar estudiante del curso'}
				student={selectStudentDelete}
				labelButonConfirm={action === 'request'
					? 'Confirmar solicitud'
					: 'Eliminar alumno'
				}
				message={action === 'request'
					? '¿Estás seguro de solicitar la baja del estudiante '
					: '¿Estás seguro de eliminar al estudiante '
				}
			/>
			<ModalAddStudentToCourse
				open={openAddStudentToCourseModal}
				setOpen={setOpenAddStudentToCourseModal}
				idCourse={idCourse}
			/>
		</ContainerFull>
	)
}