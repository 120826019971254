import React from 'react'
import { Modal } from '../../../components/modal/Modal'
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const ModalCreateCourse = ({ open, setOpen, cancelButtonRef, confirmAction, title, message, labelButonConfirm, action }) => {

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            cancelButtonRef={cancelButtonRef}
        >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className={classNames(
                        action === 'create' ? 'bg-green-600' : 'bg-orange-400',
                        'mx-auto flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10'
                    )}>
                        {
                            action === 'create'
                                ? <PlusIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                : <PencilSquareIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        }


                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            {title}
                        </Dialog.Title>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                {message}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-4">
                <button
                    type="button"
                    className={classNames(
                        action === 'create' ? 'bg-green-600 hover:bg-green-700' : 'bg-orange-400 hover:bg-orange-500',
                        'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-smsm:ml-3 sm:w-auto'
                    )}
                    onClick={confirmAction}
                >
                    {labelButonConfirm}
                </button>
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                >
                    Cancelar
                </button>
            </div>
        </Modal>
    )
}
