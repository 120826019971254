import React from 'react';
import { Disclosure, Menu } from '@headlessui/react';
import { DropDownMovilMenu } from './DropDownMovilMenu';
import { ButtonMenuMovil } from './ButtonMenuMovil';
import { Logo } from './Logo';
import { MenuNavbar } from './MenuNavbar';
import { UserMenu } from './UserMenu';
import { ButtonUserMenu } from './ButtonUserMenu';


export const Navbar = () => {

    return (
        <>
            <Disclosure as="nav" className="bg-white fixed w-full z-10 shadow-sm shadow-indigo-950/10">
                {({ open }) => (
                    <>
                        <div className="mx-auto  max-w-full px-4 sm:px-6 lg:px-0">
                            <div
                                className="flex h-20 items-center justify-between"
                            >
                                
                                <div className="flex w-[50%] md:w-[20%] justify-center">
                                    <Logo/>
                                </div>
                                <div className='flex w-full justify-center bg-white'>
                                    <MenuNavbar/>
                                </div>
                                <div className="hidden md:flex w-[20%] justify-center">
                                        {/* PROFILE DROPDOWN */}
                                        <Menu as="div" className="flex justify-end relative">
                                            {/* BUTTON USER MENU */}
                                            <ButtonUserMenu/>
                                            {/* USER MENU */}
                                            <UserMenu/>
                                        </Menu>
                                </div>
                                {/* MOBILE MENU BUTTOM */}
                                <div className="flex md:hidden">
                                    <ButtonMenuMovil open={open} />
                                </div>                                
                            </div>
                        </div>
                        {/* DROP DOWN MOBILE MENU */}
                        <DropDownMovilMenu />
                    </>
                )}
            </Disclosure >

        </>
    )
}
