import { useState } from 'react';

export const InputAvatar = ({ avatars, state, setState }) => {
    const [selectedAvatar, setSelectedAvatar] = useState(null);

    const handleAvatarChange = (event) => {
        const selectedUrl = event.target.value;
        setState((prevData) => ({
            ...prevData,
            avatarUrl: selectedUrl,
        }));
        setSelectedAvatar(selectedUrl);
    };

    return (
        <div className="w-full grid grid-cols-3 gap-4">
            {avatars.map((avatar, index) => (
                <label key={index} className="avatar-label relative">
                    <input
                        type="radio"
                        name="avatar"
                        value={avatar.urlAvatar}
                        checked={state.avatar === avatar.urlAvatar}
                        onChange={handleAvatarChange}
                        className="hidden"
                    />
                    <img
                        src={avatar.urlAvatar}
                        alt={`Avatar ${index}`}
                        className={`avatar-image rounded-full w-24 h-24 border-2 border-transparent cursor-pointer ${selectedAvatar === avatar.urlAvatar ? 'ring-4 ring-indigo-600' : ''
                            }`}
                    />
                </label>
            ))}
        </div>
    );
}
