import React from 'react';
import { Switch } from '@headlessui/react';

export const InputSwitch = ({ enabled, setEnabled, label, disabled }) => {
    return (
        <div className='flex gap-4 my-2'>
            <label
                htmlFor={""}
                className={`${disabled ? 'text-gray-400' : 'text-gray-900'} text-[16px] mb-2 font-medium leading-6`}
            >
                {label}
            </label>
            <Switch
                checked={enabled}
                onChange={setEnabled}
                disabled={disabled}
                className={`${enabled ? 'bg-indigo-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
                {/* <span className="sr-only">Enable notifications</span> */}
                <span
                    className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
            </Switch>
        </div>
    )
}
