import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { ContainerFull } from '../../components/ContainerFull';
import { ComboBox } from '../../components/comboBox/ComboBox';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';

import { optionsAllCourseIdentifier } from '../../redux/actions/options';
import { getCourseByIdentifier } from '../../redux/actions/course';
import { capitalizarPalabras } from '../../common/upperCaseWord';
import { DataGrid, esES } from '@mui/x-data-grid';
import { formatDate, validateSubscription } from '../../common/formatDateText';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export const CourseStatus = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const identifier = params?.identifier || '';

    const { identifiers } = useSelector((state) => state.options);
    const { courseListSelected } = useSelector((state) => state.course);

    const [findCourseIdentifier, setFindCourseIdenfier] = useState('');
    const [searchText, setSearchText] = useState('');
    const [formData, setFormData] = useState({
        identifier: '',
    });

    useEffect(() => {
        dispatch(optionsAllCourseIdentifier());
    }, [dispatch]);

    useEffect(() => {
        if (identifier !== 'empty') {
            dispatch(getCourseByIdentifier(identifier));
        }
    }, [identifier]);


    const handleSearchChange = (event) => {
        const searchText = event.target.value;
        setSearchText(searchText);
    };

    const validateForm = () => {
        if (!formData?.identifier) {
            toast.error('Ingresa un identificador');
            return false;
        }
        return true;
    }

    const handleSearchingCourse = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            // console.log(formData)
            dispatch(getCourseByIdentifier(formData.identifier.id));
        }
    }

    const filteredCourseIdentifier = findCourseIdentifier === ''
        ? identifiers
        : identifiers.filter((identifier) =>
            identifier.description
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findCourseIdentifier.toLowerCase().replace(/\s+/g, ''))
        );

    const rows = courseListSelected;

    const columns = [
        { field: 'matricula', headerName: 'Matricula', flex: 1 },
		{
			field: 'firstName',
			headerName: 'Nombre',
			flex: 1,
			renderCell: (params) => (
				<p>{capitalizarPalabras(params.value)}</p>
			),
		},
		{
			field: 'lastName',
			headerName: 'Apellido',
			flex: 1,
			renderCell: (params) => (
				<p>{capitalizarPalabras(params.value)}</p>
			),
		},
		{ field: 'email', headerName: 'Correo Electronico', flex: 1 },
		{
			field: 'phone',
			headerName: 'Telefono',
			flex: 1,
			renderCell: (params) => (
				<p>{capitalizarPalabras(params.value)}</p>
			),
		},
		{
			field: 'subscriptionPayment',
			headerName: 'Monto de la suscription',
			flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'paymentDeadlineDate',
			headerName: 'Fecha de vencimiento',
			flex: 1,
			renderCell: (params) => (
				<p className={`capitalize ${validateSubscription(params.value) ? 'text-green-600' : 'text-red-500'}`}>{formatDate(params.value)}</p>
			),
		},
		{
			field: 'status',
			headerName: 'Estatus',
			flex: 1,
			renderCell: (params) => (
				<div className='w-full justify-center items-center'>
					<div className={classNames(
						params.value === 'pagado'
							? 'text-lime-600 ring-lime-600 shadow-lime-600'
							: params.value === 'vencida' ? 'text-red-500 ring-red-500 shadow-red-500' : null,
						'w-[80%] flex justify-center mx-auto px-4 py-1.5 border-none ring-2 shadow-sm rounded-sm uppercase font-bold overflow-hidden'
					)}>
						<span className="text-[.8rem]">{params.value}</span>
					</div>
				</div>
			),
		},
	];


	const filteredRows = rows.filter(
		(row) =>
            row.matricula?.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			row.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.email?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
			// row.coordinador?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.status?.toLowerCase().includes(searchText.toLowerCase()) ||
            formatDate(row.paymentDeadlineDate)?.toLowerCase().includes(searchText.toLowerCase())
	);


    return (
        <ContainerFull>
            <Heading
                title={'Buscar curso por identificador'}
                center={false}
            />
            <div className='w-full flex flex-col lg:flex-row lg:justify-between mt-4'>
                <form
                    onSubmit={(e) => handleSearchingCourse(e)}
                    className='flex flex-col w-full lg:flex-row items-center lg:items-end gap-4'
                >
                    <div className='flex w-full lg:w-1/4'>
                        <div className='w-full'>
                            <ComboBox
                                filterData={filteredCourseIdentifier}
                                query={findCourseIdentifier}
                                setQuery={setFindCourseIdenfier}
                                selected={formData}
                                setSelected={setFormData}
                                placeholder='Seleciona tu curso'
                                property='identifier'
                            />
                        </div>
                    </div>
                    <div className='flex w-full lg:w-1/6'>
                        <button
                            type='submit'
                            className='disabled:opacity-95 disabled:cursor-not-allowed rounded-md hover:opacity-80 transition py-2.5 font-semibold text-md text-white bg-indigo-600 bg-cyan w-full'
                        >{'Buscar curso'}</button>
                    </div>
                </form>
            </div>
            <Wrapper>
                <input
                    type="text"
                    placeholder="Buscar un dentro del curso"
                    value={searchText}
                    onChange={handleSearchChange}
                    className='block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' />
            </Wrapper>
            <Wrapper>
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    // onRowClick={handleRowClick}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 15, page: 0, },
                        },
                    }}
                    pageSizeOptions={[10, 15, 25]}
                />
            </Wrapper>
        </ContainerFull>
    )
}
