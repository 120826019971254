import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { ContainerFull } from '../../components/ContainerFull';
import { Wrapper } from '../../components/Wrapper';
import { MessageImage } from './components/MessageImage';
import { MessageText } from './components/MessageText';
import { MessageUrl } from './components/MessageUrl';
import { MessageFile } from './components/MessageFile';
import { MessageMenu } from './components/MessageMenu';
import { ModalAddMessageUrl } from './components/ModalAddMessageUrl';
import { ModalAddMessageImage } from './components/ModalAddMessageImage';

import { addChatMessageToCourse, getChatByIdCourse, getCourseById, getListStudentsByIdCourse } from '../../redux/actions/course';

import { urlFlag } from '../../common/urlBase';
import { firstCapitalLetter } from '../../common/upperCaseWord';

import { LinkIcon, PhotoIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ChatBubbleBottomCenterTextIcon, ChatBubbleLeftRightIcon, PresentationChartLineIcon } from '@heroicons/react/24/outline';
import { ModalAddMessageNotice } from './components/ModalAddMessageNotice';
import { getNoticeMessages } from '../../redux/actions/noticeMessage';
import { CardNoticeMessage } from './components/CardNoticeMessage';
import { formatDateTime } from '../../common/formatDateText';


export const CourseDisplay = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const idCourse = params?.idCourse;
    const chatContainerRef = useRef(null);
    const [openAddMessageUrlModal, setOpenAddMessageUrlModal] = useState(false);
    const [openAddMessageImageModal, setOpenAddMessageImageModal] = useState(false);
    const [openAddMessageNoticeModal, setOpenAddMessageNoticeModal] = useState(false);
    const [showMural, setShowMural] = useState('chat')

    const { courseListSelected, messagesCourse, courseSelected } = useSelector((state) => state.course);
    const { user } = useSelector((state) => state.user);
    const { typeUser } = user;
    const { allNoticeMessages } = useSelector((state) => state.noticeMessage);
    const [formData, setFormData] = useState({
        message: '',
    });

    const scrollToBottom = () => {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    };

    useEffect(() => {
        scrollToBottom();
    }, [messagesCourse]);

    useEffect(() => {
        if (idCourse) {
            dispatch(getCourseById(idCourse));
            dispatch(getListStudentsByIdCourse(idCourse));
            dispatch(getChatByIdCourse(idCourse));
            dispatch(getNoticeMessages(idCourse));
        }
    }, [idCourse]);

    useEffect(() => {
        if (status === 'completado') {
            navigate(`/cursos`);
        }
    }, [courseSelected]);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.message.trim() === '') {
            return toast.error('Ingresa un mensaje');
        }
        dispatch(addChatMessageToCourse(idCourse, user._id, formData.message, '', '', 'text'))
            .then((result) => {
                if (result.status === 201) {
                    setFormData({
                        message: ''
                    });
                } else {
                    toast.error(result.message);
                    setFormData({
                        message: ''
                    });
                }
            });
    }

    const { days, hours, language, level, teacher, status } = courseSelected;

    return (
        <ContainerFull>
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='hidden lg:flex flex-col lg:w-[25%] h-[80vh] gap-4'>
                        <div className='flex border border-gray-200 rounded-lg px-4 py-2 h-[35%]'>
                            <div className='flex flex-col'>
                                <div className='flex items-center'>
                                    <img className="w-[24px] mr-3" src={`${urlFlag}${language?.path}`} alt="flag" />
                                    <p className='text-[1.1rem] font-semibold text-gray-950'>{`Curso de ${firstCapitalLetter(language?.name)} - ${firstCapitalLetter(level?.name)}`}</p>
                                </div>
                                <p className='text-[.8rem] text-gray-600 mt-2'>Dias de clase</p>
                                <div className='flex flex-wrap gap-4 mt-1'>
                                    {
                                        days?.length && days.map(({ day, simbolo }) => (
                                            <p key={day} className='capitalize text-[.8rem] font-semibold rounded-sm px-2 bg-gray-100'>{simbolo}</p>
                                        ))
                                    }

                                </div>
                                <p className='text-[.8rem] text-gray-600 mt-2'>Horario</p>
                                <div className='flex mt-1'>
                                    {
                                        hours?.length && (
                                            <p className='flex mr-2 capitalize text-[.8rem] font-semibold rounded-sm px-2'>{`${hours[0]?.time} - ${hours[hours.length - 1]?.time}`}</p>
                                        )
                                    }

                                </div>

                                <p className='text-[.8rem] text-gray-600 mt-2'>Profesor</p>
                                <div className='flex mt-1'>
                                    <p className='flex mr-2 capitalize text-[.8rem] font-semibold rounded-sm  px-2'>{teacher ? `${teacher?.firstName} ${teacher.lastName}` : '-'}</p>
                                </div>
                            </div>
                        </div>

                        <div className='border border-gray-200 rounded-lg p-4 h-[65%]'>
                            <p className='text-[.9rem] text-gray-600'>Estudiantes</p>
                            <div className='h-[95%] overflow-auto p-4'>
                                {
                                    courseListSelected?.length
                                        ? courseListSelected.map(({ _id, firstName, lastName, matricula }) => (
                                            <p key={`${_id}-${matricula}`} className='text-[.8rem] text-gray-950 p-2 border-b border-gray-200 capitalize'>{(`[${matricula}] - ${firstName} ${lastName}`)}</p>

                                        ))
                                        : <p className='text-[1rem] text-gray-950 p-2 border-b border-gray-200'>No hay alumnos inscritos</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex lg:hidden'>
                        {/* <button
                            // disabled={status === 'completado'}
                            onClick={() => { }}
                            className="bg-indigo-600 disabled:bg-slate-400 disabled:cursor-not-allowed flex-1 py-2 text-white font-semibold rounded-md focus:bg-indigo-700"
                        >Editar lista de alumnos</button> */}
                        <button
                            type='button'
                            onClick={() => showMural === 'chat' ? setShowMural('mural') : setShowMural('chat')}
                            className='w-full flex justify-center items-center px-4 py-3 rounded-md bg-sky-500 hover:bg-sky-700 shadow-md'>
                            {
                                showMural === 'chat'
                                    ? <PresentationChartLineIcon className="w-6 mr-4 text-white" aria-hidden="true" />
                                    : <ChatBubbleLeftRightIcon className="w-6 mr-4 text-white" aria-hidden="true" />
                            }
                            <p className='text-white font-semibold'>{showMural === 'chat' ? 'Mostrar muro de avisos' : 'Mostrar chat'}</p>
                        </button>
                    </div>
                    <div className={classNames(
                        showMural !== 'chat'
                            ? 'hidden'
                            : null,
                        'w-full lg:block lg:w-[50%] border-[1px] border-gray-200 rounded-lg h-[70vh] lg:h-[80vh] transition-all ease-out'
                    )}>
                        <div className='p-6 w-full h-[90%] overflow-auto' ref={chatContainerRef}>
                            {
                                messagesCourse?.length
                                    ? messagesCourse.map(({ _id: messageId, timestamp, publicId, content, url, messageType, sender }) => {
                                        const { _id: userId, firstName, lastName, typeUser } = sender;
                                        return (
                                            <div
                                                className={classNames(
                                                    typeUser === 'estudiante'
                                                        ? `bg-gray-50 mr-auto`
                                                        : typeUser !== 'estudiante' && typeUser !== 'profesor'
                                                            ? `bg-violet-100 ml-auto`
                                                            : `bg-indigo-100 ml-auto`,
                                                    'w-full lg:w-[80%] px-4 py-2 border rounded-lg mb-2 break-words'
                                                )}
                                                key={messageId}
                                            >
                                                <div className='flex justify-between'>
                                                    <p className='capitalize text-[.9rem] font-semibold'>{`${firstName} ${lastName}`}
                                                        {/* <small className='text-indigo-600'>{` - [ ${matricula} ]`}</small> */}
                                                    </p>
                                                    {
                                                        userId === user?._id
                                                            ? <MessageMenu
                                                                courseId={idCourse}
                                                                chatId={courseSelected.idChat}
                                                                userId={userId}
                                                                messageId={messageId}
                                                                typeMessage={messageType}
                                                                publicId={publicId}
                                                            />
                                                            : null
                                                    }
                                                </div>
                                                {
                                                    messageType === 'text'
                                                        ? <MessageText content={content} />
                                                        : messageType === 'image'
                                                            ? <MessageImage content={content} url={url} />
                                                            : messageType === 'url'
                                                                ? <MessageUrl content={content} url={url} />
                                                                : messageType === 'file'
                                                                    ? <MessageFile content={content} url={url} />
                                                                    : null
                                                }

                                                <p className='capitalize text-[.7rem] tracking-wider font-semibold text-right text-gray-500 mt-2'>{formatDateTime(timestamp)}</p>
                                            </div>
                                        )
                                    })
                                    : <p className='text-[1rem] text-gray-950 p-2 border-b border-gray-200'>No hay mensajes</p>
                            }
                        </div>
                        <div className='flex px-2 h-[10%]'>
                            <div className='w-full flex flex-row justify-between items-center gap-2 border-t border-gray-100'>
                                <button
                                    onClick={() => setOpenAddMessageUrlModal(true)}
                                    className='w-[15%] lg:w-[5%] flex justify-center items-center border border-gray-200 rounded-md lg:rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent'>
                                    <LinkIcon className='text-indigo-600 w-[16px] py-1 lg:py-0  lg:w-[24px]' />
                                </button>
                                <button
                                    onClick={() => setOpenAddMessageImageModal(true)}
                                    className='w-[15%] lg:w-[5%] flex justify-center items-center border border-gray-200 rounded-md lg:rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent'>
                                    <PhotoIcon className='text-indigo-600 w-[16px] py-1 lg:py-0  lg:w-[24px]' />
                                </button>
                                <form className='flex w-full gap-2' onSubmit={handleSubmit}>
                                    <input
                                        name='message'
                                        type="text"
                                        value={formData.message}
                                        onChange={onChange}
                                        placeholder='Agrega un mensaje...'
                                        className='w-[80%] lg:w-[95%] border border-gray-200 rounded-md lg:rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent'
                                    />
                                    <button
                                        type='submit'
                                        className='w-[20%] lg:w-[5%] flex justify-center items-center border border-gray-200 rounded-md lg:rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent'>
                                        <PaperAirplaneIcon className='text-indigo-600 w-[16px] py-1 lg:py-0  lg:w-[24px]' />
                                    </button>
                                </form>
                            </div>

                        </div>
                    </div>
                    <div className={classNames(
                        showMural !== 'mural'
                            ? 'hidden'
                            : null,
                        'lg:flex flex-col lg:w-[25%] h-[70vh] lg:h-[80vh] border border-gray-200 rounded-lg transition-all ease-out'
                    )}>
                        <div className='flex justify-between items-center p-4 h-[10%]'>
                            <p className='font-semibold text-[1.4rem]'>Muro de avisos</p>
                            {
                                typeUser !== 'estudiante' && (
                                    <button
                                        type='button'
                                        onClick={() => setOpenAddMessageNoticeModal(true)}
                                        className='flex justify-center items-center px-4 py-2 rounded-md bg-green-500 shadow-md'>
                                        <p className='text-white font-semibold'>Agregar</p>
                                        <ChatBubbleBottomCenterTextIcon className="w-6 ml-2 text-white" aria-hidden="true" />
                                    </button>
                                )
                            }
                        </div>

                        <div className='flex flex-col gap-4 p-4 h-[90%] overflow-auto'>
                            {
                                allNoticeMessages?.length
                                    ? allNoticeMessages.map(({ _id, urlName, message, createdBy, createdAt, link }) => (
                                        <CardNoticeMessage
                                            key={_id}
                                            urlName={urlName}
                                            message={message}
                                            createdBy={createdBy}
                                            createdAt={createdAt}
                                            link={link}
                                            messaageMuroId={_id}
                                        />
                                    ))
                                    : <p className='text-[1rem] text-gray-950 p-2 border-b border-gray-200'>No hay publicaciones de avisos</p>

                            }


                        </div>
                    </div>
                </div>
            </Wrapper>
            <ModalAddMessageUrl
                open={openAddMessageUrlModal}
                setOpen={setOpenAddMessageUrlModal}
                courseId={idCourse}
                userId={user._id}
            />
            <ModalAddMessageImage
                open={openAddMessageImageModal}
                setOpen={setOpenAddMessageImageModal}
                courseId={idCourse}
                userId={user._id}
            />
            <ModalAddMessageNotice
                open={openAddMessageNoticeModal}
                setOpen={setOpenAddMessageNoticeModal}
                courseId={idCourse}
                userId={user._id}
            />

        </ContainerFull>
    )
}
