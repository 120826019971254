import { useRef } from 'react';
import { Dialog } from '@headlessui/react';

import { InputAvatar } from '../../components/inputAvatar/InputAvatar';
import { Modal } from '../../components/modal/Modal';

import { avatars } from '../../static/data';

export const ChangeAvatarModal = ({ open, setOpen, state, setState }) => {
	const cancelButtonRef = useRef(null);

	return (
		<Modal open={open} setOpen={setOpen} cancelButtonRef={cancelButtonRef}>
			<div className="bg-white px-4 py-4">
				<div className="flex justify-center items-center">

					<div>
						<Dialog.Title as="h3" className="text-center text-lg font-semibold leading-6 text-gray-950">
							Seleciona un nuevo avatar
						</Dialog.Title>
						<div className="w-full mt-10">
							<InputAvatar
								avatars={avatars}
								state={state}
								setState={setState}
							/>
						</div>
						<div className='flex items-start mt-8'>
							<button
								onClick={() => setOpen(false)}
								type='button'
								className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 hover:bg-indigo-700 w-[100%]'
							>Cerrar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
