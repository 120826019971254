import { createSlice } from '@reduxjs/toolkit';

const noticeMessageSlice = createSlice({
    name: 'noticeMessage',
    initialState: {
        allNoticeMessages: [],
        loading: false,
    },
    reducers: {
        noticeMessageStart: (state) => {
            state.loading = true;
        },
        noticeMessageSuccess: (state) => {
            state.loading = false;
        },
        noticeMessageGetAllSuccess: (state, {payload: {data}} ) => {
            state.loading = false;
            state.allNoticeMessages = data;
        },
        noticeMessageDeleteSuccess: (state, {payload: {data}} ) => {
            state.loading = false;
            state.allNoticeMessages = data;
        },
        noticeMessageFailure: (state) => {
            state.loading = false;
        },
        cleanNoticeMessage: (state) => {
            state.allNoticeMessages = [];
        },
    },
});

export const {
    noticeMessageStart,
    noticeMessageSuccess,
    noticeMessageGetAllSuccess,
    noticeMessageDeleteSuccess,
    noticeMessageFailure,
    cleanNoticeMessage,
} = noticeMessageSlice.actions;
export default noticeMessageSlice.reducer;