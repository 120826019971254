import {
    noticeMessageStart,
    noticeMessageSuccess,
    noticeMessageGetAllSuccess,
    noticeMessageFailure,
    cleanNoticeMessage,
    noticeMessageDeleteSuccess,
} from '../reducers/noticeMessage';

import axios from '../../api/axios';

export const createNewNoticeMessage = (
    file,
    courseId,
    userId,
    message,
    link
) => async (dispatch) => {
    dispatch(noticeMessageStart());
    try {
        if (!file) {
            const response = await axios.post('/noticeMessage/createNewNoticeMessage', {
                userId,
                courseId,
                message,
                link
            });
            if (response.status === 201) {
                dispatch(noticeMessageSuccess());
                return {
                    status: response.status,
                    message: response.data.message
                };
            }
        } else {
            const formData = new FormData();
            formData.append('file', file);
            formData.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
            formData.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
            formData.append('upload_preset', 'fca-intranet');

            const res = await axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, formData);

            if (res.status === 200) {
                const response = await axios.post('/noticeMessage/createNewNoticeMessage', {
                    urlName: res.data.secure_url,
                    publicId: res.data.public_id,
                    userId,
                    courseId,
                    message,
                    link
                });
                if (response.status === 201) {
                    dispatch(noticeMessageSuccess());
                    return {
                        status: response.status,
                        message: response.data.message
                    };
                }
            }
        }
    } catch (error) {
        dispatch(noticeMessageFailure());
    }
};

export const getNoticeMessages = (courseId) => async (dispatch) => {
    dispatch(noticeMessageStart());
    try {
        const response = await axios.get(`/noticeMessage/getAllNoticeMessage/${courseId}`);
        if (response.status === 200) {
            dispatch(noticeMessageGetAllSuccess(response.data));
        }
        return response.data;
    } catch (error) {
        dispatch(noticeMessageFailure());
    }
}

export const deleteNoticeMessages = (messaageMuroId) => async (dispatch) => {
    dispatch(noticeMessageStart());
    try {
        const response = await axios.delete(`/noticeMessage/deleteNewNoticeMessage/${messaageMuroId}`);
        if (response.status === 200) {
            dispatch(noticeMessageDeleteSuccess(response.data));
            return {
                status: response?.status || null,
                message: response?.data.message
            };
        }
    } catch (error) {
        dispatch(noticeMessageFailure());
    }
}

export const cleanActionNoticeMessages = () => async (dispatch) => {
    dispatch(cleanNoticeMessage());
}