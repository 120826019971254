import { useSelector } from 'react-redux';
import { ImageZoomFull } from '../../../components/imageZoom/ImageZoomFull'
import { MessageMenuMuro } from './MessageMenuMuro';
import { formatDateTime } from '../../../common/formatDateText';

export const CardNoticeMessage = ({ messaageMuroId, urlName, message, createdBy, createdAt, link }) => {
    const { user } = useSelector((state) => state.user);
    const { _id: userId } = user;
    const { firstName, lastName } = createdBy;
    return (
        <div className='w-full flex flex-col border border-gray-200 rounded-lg p-4'>
            {
                urlName && <ImageZoomFull imageUrl={urlName} />
            }
            {
                message && (
                    <div className='border rounded-lg bg-indigo-50 my-4 break-words p-4'>
                        <div className='flex justify-between'>
                            <p className='text-[.9rem] font-semibold text-gray-500 mb-2'>Mensaje de: <span className='text-[1rem] text-indigo-600 capitalize ml-1'>{`${firstName} ${lastName}`}</span></p>
                            {
                                userId === createdBy._id && (
                                    <MessageMenuMuro 
                                        messaageMuroId={messaageMuroId}
                                    />
                                )
                            }
                        </div>
                        <p className='text-[1rem] font-normal text-gray-600 ml-2 break-words'>{message}</p>
                    </div>
                )

            }
            {
                link && (
                    <div className='border rounded-lg bg-indigo-50 my-4 break-words p-4'>
                        <p className='text-[.9rem] font-semibold text-gray-500'>Enlace</p>
                        <a className='text-[1rem] font-semibold text-indigo-500 ml-2 break-words' href={link} target="_blank">{link}</a>
                    </div>
                )
            }
            <p className='capitalize text-[.7rem] tracking-wider font-semibold text-right text-gray-500 mt-2'>{formatDateTime(createdAt)}</p>
        </div>
    )
}
