import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Title } from "../../components/Title";
import { Heading } from "../../components/Heading";
import { Wrapper } from "../../components/Wrapper";
import { PaymentHistory } from "../payments/PaymentHistory";
import { PropertyItem } from "../../components/PropertyItem";
import { LoaderSection } from "../../components/LoaderSection";
import { ContainerFull } from "../../components/ContainerFull";
import { PropertyListItem } from "../../components/PropertyListItem";
import { CardCourseDisplay } from "../courses/components/CardCourseDisplay";

import { getUserById } from "../../redux/actions/users";
import { optionsAllCourseListOpenByIdUser } from "../../redux/actions/options";
import { cleanActionSelectedPayment, getAllPaymentByUserId } from "../../redux/actions/payment";

import { formatDate } from "../../common/formatDateText";
import { capitalizarPalabras, firstCapitalLetter } from "../../common/upperCaseWord";

export const ProfileUser = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { userId } = params;
    const { userSelected } = useSelector((state) => state.users);
    const { coursesListByIdUser } = useSelector((state) => state.options);

    useEffect(() => {
        dispatch(getUserById(userId))
    }, [userId, dispatch]);

    useEffect(() => {
        dispatch(cleanActionSelectedPayment())
    }, [dispatch])

    useEffect(() => {
        dispatch(getAllPaymentByUserId(userId))
    }, [userId, dispatch])

    useEffect(() => {
        dispatch(optionsAllCourseListOpenByIdUser(userId));
    }, [userId, dispatch]);

    const {
        id: _id, firstName, secondName, lastName, secondSurname,
        email, phone, dateBirth, location, typeUser,
        education, paymentDeadlineDate, subscriptionPayment,
        matricula, emailTax, rfc, businessName, taxRegime
    } = userSelected;

    return (
        <ContainerFull>
            <Heading
                title={`Perfil de usuarios`}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        {userSelected ? (
                            <>
                                <Title title={'Datos personales'} />
                                <PropertyListItem>
                                    <PropertyItem
                                        title={`Matricula`}
                                        description={matricula}
                                    />
                                    <PropertyItem
                                        title={`Nombre completo`}
                                        description={`${firstCapitalLetter(firstName)} ${secondName ? firstCapitalLetter(secondName) : ''} ${firstCapitalLetter(lastName)} ${secondName ? firstCapitalLetter(secondSurname) : ''}`}
                                    />
                                    <PropertyItem
                                        title={`Correo electrónico`}
                                        description={email}
                                    />
                                    <PropertyItem
                                        title={`Telefono`}
                                        description={phone}
                                    />
                                    <PropertyItem
                                        title={`Fecha de nacieminto`}
                                        description={capitalizarPalabras(formatDate(dateBirth))}
                                    />
                                    {
                                        typeUser !== 'estudiante' && (
                                            <PropertyItem
                                                title={`Tipo de usuario`}
                                                description={firstCapitalLetter(typeUser)}
                                            />
                                        )
                                    }
                                    {
                                        typeUser === 'estudiante' && (
                                            <PropertyItem
                                                title={`Nivel de educacion`}
                                                description={capitalizarPalabras(education)}
                                            />
                                        )
                                    }
                                    <PropertyItem
                                        title={`Lugar de residencia`}
                                        description={capitalizarPalabras(location)}
                                    />
                                    {
                                        typeUser === 'estudiante' && (
                                            <PropertyItem
                                                title={`Monto a pagar`}
                                                description={subscriptionPayment ? subscriptionPayment : 'No especificado'}
                                            />
                                        )
                                    }
                                    {
                                        typeUser === 'estudiante' && (
                                            <PropertyItem
                                                title={`Proximo fecha de pago`}
                                                description={capitalizarPalabras(formatDate(paymentDeadlineDate))}
                                            />
                                        )
                                    }
                                </PropertyListItem>
                            </>
                        )
                            : (
                                <LoaderSection />
                            )
                        }
                        {
                            typeUser === 'estudiante' && (
                                <div className="mt-10">
                                    <Title title={'Datos fiscales'} />
                                    <PropertyListItem>
                                        <PropertyItem
                                            title={`Correo electrónico fiscal`}
                                            description={emailTax}
                                        />
                                        <PropertyItem
                                            title={`RFC`}
                                            description={rfc ? rfc.toUpperCase() : ''}
                                        />
                                        <PropertyItem
                                            title={`Razon social`}
                                            description={businessName}
                                        />
                                        <PropertyItem
                                            title={`Regimen fiscal`}
                                            description={taxRegime}
                                        />
                                    </PropertyListItem>
                                </div>
                            )
                        }
                    </div>
                    {
                        typeUser === 'estudiante' && (
                            <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                                <Title title={'Historial de pagos del estudiante'} />
                                <div className="w-full h-[600px] bg-indigo-900/5 rounded-md mt-4 overflow-auto p-4">
                                    <PaymentHistory action={false} />
                                </div>
                            </div>
                        )}
                </div>
            </Wrapper>
            {
                (typeUser === 'estudiante' || typeUser === 'profesor') && (
                    <div className='w-full lg:w-[40%]'>

                        <Wrapper>
                            <div className='w-full flex flex-col lg:flex-row gap-4'>
                                <div className='w-full border-[1px] border-gray-200 rounded-lg p-4'>
                                    <Title title={'Historial de cursos del estudiante'} />
                                    <div className="w-full lg:w-[90%] mx-auto">
                                        {
                                            coursesListByIdUser.length
                                                ? coursesListByIdUser.map(({ id, headerImage, language, path, color, level, status, teacher, fromDate, toDate, days, hours }) => (
                                                    <CardCourseDisplay
                                                        key={id}
                                                        id={id}
                                                        headerImage={headerImage}
                                                        language={language}
                                                        path={path}
                                                        color={color}
                                                        level={level}
                                                        status={status}
                                                        teacher={teacher}
                                                        fromDate={fromDate}
                                                        toDate={toDate}
                                                        days={days}
                                                        hours={hours}
                                                        action={false}
                                                    />
                                                ))
                                                : (
                                                    <p className='text-gray-400'>No hay historial de cursos.</p>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                )
            }
        </ContainerFull>
    )
}
