import { createSlice } from '@reduxjs/toolkit';

const paymentSlice = createSlice({
	name: 'payment',
	initialState: {
		loading: false,
		payments: [],
		paymentsByUser: [],
		paymentSelected: {},
	},
	reducers: {
		getAllPaymentsStart: (state) => {
			state.loading = true;
		},
		getAllPaymentsSuccess: (state, { payload: { payments } }) => {
			state.loading = false;
            state.payments = payments;
		},
		getAllPaymentsFailure: (state) => {
			state.loading = false;
		},
		getAllPaymentsByUserIdStart: (state) => {
			state.loading = true;
		},
		getAllPaymentsByUserIdSuccess: (state, { payload: { payments } }) => {
			state.loading = false;
            state.paymentsByUser = payments;
		},
		getAllPaymentsByUserIdFailure: (state) => {
			state.loading = false;
		},
		getPaymentByIdStart: (state) => {
			state.loading = true;
		},
		getPaymentByIdSuccess: (state, { payload: { payment } }) => {
			state.loading = false;
            state.paymentSelected = payment;
		},
		getPaymentByIdFailure: (state) => {
			state.loading = false;
		},
		uploadPaymentStart: (state) => {
			state.loading = true;
		},
		uploadPaymentSuccess: (state) => {
			state.loading = false;
		},
		uploadPaymentFailure: (state) => {
			state.loading = false;
		},
		updatePaymentStart: (state) => {
			state.loading = true;
		},
		updatePaymentSuccess: (state) => {
			state.loading = false;
		},
		updatePaymentFailure: (state) => {
			state.loading = false;
		},
		declinePaymentStart: (state) => {
			state.loading = true;
		},
		declinePaymentSuccess: (state) => {
			state.loading = false;
		},
		declinePaymentFailure: (state) => {
			state.loading = false;
		},
		acceptedPaymentStart: (state) => {
			state.loading = true;
		},
		acceptedPaymentSuccess: (state) => {
			state.loading = false;
		},
		acceptedPaymentFailure: (state) => {
			state.loading = false;
		},
        cleanSelectedPayment: (state) => {
            state.payments = [];
            state.paymentSelected = {};
            state.paymentsByUser = [];
        }
	},
});

export const {
	getAllPaymentsStart,
	getAllPaymentsSuccess,
	getAllPaymentsFailure,
	getAllPaymentsByUserIdStart,
	getAllPaymentsByUserIdSuccess,
	getAllPaymentsByUserIdFailure,
	getPaymentByIdStart,
	getPaymentByIdSuccess,
	getPaymentByIdFailure,
	uploadPaymentStart,
	uploadPaymentSuccess,
	uploadPaymentFailure,
	updatePaymentStart,
	updatePaymentSuccess,
	updatePaymentFailure,
	declinePaymentStart,
	declinePaymentSuccess,
	declinePaymentFailure,
	acceptedPaymentStart,
	acceptedPaymentSuccess,
	acceptedPaymentFailure,
    cleanSelectedPayment,
} = paymentSlice.actions;
export default paymentSlice.reducer;