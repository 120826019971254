import * as XLSX from 'xlsx';

export const exportToExcel = (data, filename) => {
    // Verificar si el objeto de datos está vacío
    if (!data || data.length === 0) {
        return {
            data: true,
            message:'No hay datos para exportar.'
        };
    }

    try {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Lista de estudiantes");
        XLSX.writeFile(workbook, filename);
        return {
            data: false,
            message:`Datos exportados exitosamente a ${filename}`
        };
    } catch (error) {
        return {
            data: true,
            message:`Ocurrió un error al exportar los datos a Excel: ${error}`
        };
    }
}