import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';
import { CardCourse } from './components/CardCourse';

import { useDispatch, useSelector } from 'react-redux';
import { cleanActionSelectedCourse, getAllCourses } from '../../redux/actions/course';
import { CardCourseUser } from './components/CardCourseUser';
import { cleanActionNoticeMessages } from '../../redux/actions/noticeMessage';
import { SquaresPlusIcon } from '@heroicons/react/20/solid';
import { Tooltip, Zoom } from '@mui/material';

export const CoursesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.user);
    const { _id: userId, roles, typeUser } = user;
    const courses = useSelector((state) => state.course.courses);

    useEffect(() => {
        dispatch(cleanActionSelectedCourse())
        dispatch(cleanActionNoticeMessages())
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllCourses(userId, roles))
    }, [dispatch]);


    const handleCreateNewCourse = () => {
        navigate('/nuevo-curso');
    }

    return (
        <ContainerFull>
            <Heading
                title={roles === 'admin' ? 'Lista de los cursos' : 'Mis cursos'}
                center={false}
            />
            {
                <div className='w-full flex justify-end gap-4 mt-4'>
                    {
                        typeUser === 'director' && (
                            <Tooltip title="Agregar un nuevo curso" TransitionComponent={Zoom} placement='top'>
                                <button
                                    type='button'
                                    onClick={handleCreateNewCourse}
                                    className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
                                ><SquaresPlusIcon className="block h-5 w-5" aria-hidden="true" /></button>
                            </Tooltip>
                        )
                    }
                </div>
            }

            <Wrapper>
                <div className="w-full">

                    {
                        (roles === 'admin' || (roles === 'user' && typeUser === 'profesor')) ? (
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lx:grid-cols-4 gap-6'>
                                {courses.length ?
                                    (courses && courses.map(course => (
                                        <CardCourse
                                            key={course._id}
                                            isCreating={false}
                                            idCourse={course?._id}
                                            identifier={course?.identifier}
                                            color={course?.color}
                                            language={course?.language}
                                            path={course?.path}
                                            nivel={course?.level}
                                            studentLimit={course.limitMembers}
                                            status={course?.status}
                                            hours={course?.hours}
                                            days={course?.days}
                                            teacher={course?.teacher}
                                            fromDate={course?.fromDate}
                                            toDate={course?.toDate}
                                            headerImage={course?.headerImage?.urlName}
                                            updatedBy={course?.updatedBy}
                                            updatedAt={course?.updatedAt}
                                        />
                                    ))) : <h1>No hay ningun curso</h1>
                                }
                            </div>
                        ) : (
                            <div className='grid grid-cols-1 lg:grid-cols-1 gap-6'>
                                {courses.length ?
                                    (courses && courses.map(course => (
                                        <CardCourseUser
                                            key={course._id}
                                            idCourse={course?._id}
                                            color={course?.color}
                                            language={course?.language}
                                            path={course?.path}
                                            nivel={course?.level}
                                            status={course?.status}
                                            hours={course?.hours}
                                            days={course?.days}
                                            teacher={course?.teacher}
                                            fromDate={course?.fromDate}
                                            toDate={course?.toDate}
                                        />
                                    ))) : <h1>No hay ningun curso</h1>
                                }
                            </div>
                        )
                    }

                </div>
            </Wrapper>
        </ContainerFull>
    )
}
