import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import {
    CalendarDaysIcon,
} from '@heroicons/react/20/solid';

export const InputDateWithIcon = ({ id, value, onChange, label, placeHolder, }) => {
    return (
        <div className="w-full">
            <label
                htmlFor={label}
                className="block text-md mb-2 font-medium leading-6 text-gray-900"
            >
                {label}
            </label>
            <DatePicker
                id={id}
                selected={value}
                onChange={onChange}
                locale={es}
                dateFormat="dd/MM/yyyy"
                placeholderText={placeHolder}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={80}
                showMonthDropdown
                scrollableMonthYearDropdown
                popperPlacement="bottom-start" // Alineación del calendario
                // popperModifiers={{
                //     offset: {
                //         enabled: true,
                //         offset: '5px, 10px' 
                //     }
                // }}
                calendarClassName="bg-white border border-indigo-200 shadow-lg rounded-md z-10" // Estilos del calendario
                customInput={
                    <CustomInputWithIcon value={value} placeHolder={placeHolder} />
                }
            />
        </div>
    );
};

const CustomInputWithIcon = ({ value, onClick, placeHolder }) => (

    <div className="relative w-full">
        <input
            type="text"
            value={value}
            onClick={onClick}
            className="w-full p-2 pl-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            placeholder={placeHolder}
        />
        <CalendarDaysIcon className="absolute w-5 h-5 text-gray-500 left-3 top-1/2 transform -translate-y-1/2" />
    </div>
);
