import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import { getAllStudents } from '../../redux/actions/users';

import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';

import { formatDate, validateSubscription } from '../../common/formatDateText';

import './styles.css';
import { UserIcon } from '@heroicons/react/20/solid';
import { Loading } from '../../components/loading/Loading';
import { Tooltip, Zoom } from '@mui/material';

export const StudentsPage = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAllStudents())
	}, [dispatch])

	const {
		students,
		loading,
	} = useSelector((state) => state.users);
	const [searchText, setSearchText] = useState('');

	let savedPageState = JSON.parse(localStorage.getItem('studentsTablePageState'));

	useEffect(() => {
		getTablePageStateFromLocalStorage();
	}, []);

	// Recuperar estado inicial de la página desde localStorage
	const getTablePageStateFromLocalStorage = () => {
		if (savedPageState) {			// const memoriTableState = JSON.parse(savedPageState);
			if (savedPageState.lastUpdate === new Date().toLocaleString('es-MX').split(' ')[0]) {
				saveTableStateToLocalStorage(savedPageState.currentPage, 'tableStudents');
			}

			if (savedPageState.lastUpdate !== new Date().toLocaleString('es-MX').split(' ')[0]) {
				saveTableStateToLocalStorage(0, 'tableStudents');
			}
		}

		if (!savedPageState) {
			saveTableStateToLocalStorage(0, 'tableStudents');
		}
	};

	const rows = students;

	const columns = [
		{ field: 'matricula', headerName: 'Matricula', flex: 1 },
		{
			field: 'firstName',
			headerName: 'Nombre',
			flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'lastName',
			headerName: 'Apellido',
			flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{ field: 'email', headerName: 'Correo Electronico', flex: 1 },
		{
			field: 'phone',
			headerName: 'Telefono',
			flex: 1,
			renderCell: (params) => (
				<p>{params.value}</p>
			),
		},
		{
			field: 'createdBy',
			headerName: 'Inscrito por',
			flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'createdAt',
			headerName: 'Fecha de inscripcion',
			flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{formatDate(params.value)}</p>
			),
		},
		{
			field: 'numCourses',
			headerName: 'Cursos',
			width: 100,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'courseIdentifiers',
			headerName: 'Identificadores de cursos',
			// flex: 1,
			width: 190,
			sortable: false,
			renderCell: (params) => (
				<div className='course-identifiers'>
					{
						params.value.map(item => <p key={item} className='uppercase block ml-1'>{`[${item}]`}</p>)
					}

				</div>
			),
		},
		{
			field: 'paymentDeadlineDate',
			headerName: 'Fecha de vencimiento',
			flex: 1,
			renderCell: (params) => (
				<p className={`capitalize ${validateSubscription(params.value) ? 'text-green-600' : 'text-red-500'}`}>{formatDate(params.value)}</p>
			),
		},
		{
			field: 'action',
			headerName: '',
			width: 150,
			sortable: false,
			disableColumnMenu: true,
			renderCell: (params) => (
				<div className='w-full flex justify-center gap-4'>
					<Tooltip title="Ver perfil del estudiante" TransitionComponent={Zoom} placement='top'>
						<Link
							to={`/perfil/${params.id}`}
							className="flex justify-center shadow-sm shadow-indigo-600 bg-indigo-600 uppercase px-4 py-2 text-slate-50 rounded hover:bg-indigo-800"
						>
							<UserIcon className="block h-5 w-5" aria-hidden="true" />
						</Link>
					</Tooltip>
				</div>
			),
		},
	];

	const handleSearchChange = (event) => {
		const searchText = event.target.value;
		setSearchText(searchText);
	};

	const filteredRows = rows.filter(
		(row) =>
			row.matricula?.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			row.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.email?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.createdAtFormatted?.toLowerCase().includes(searchText.toLowerCase()) ||
			formatDate(row.paymentDeadlineDate)?.toLowerCase().includes(searchText.toLowerCase())
	);

	const handlePageChange = (currentPage) => {
		saveTableStateToLocalStorage(currentPage, 'tableStudents');
	};

	const saveTableStateToLocalStorage = (currentPage, title) => {
		const pageState = {
			lastUpdate: new Date().toLocaleString('es-MX').split(' ')[0],
			title,
			currentPage,
		};
		localStorage.setItem('studentsTablePageState', JSON.stringify(pageState));
	};


	return (
		<ContainerFull>
			<Heading
				title={`Lista de estudiantes`}
				subtitle={`Muestra la lista de todos los estudiantes registrados en la plataforma.`}
				center={false}
			/>

			{students && (
				<>
					<Wrapper>
						<input
							type="text"
							placeholder="Buscar un estudiante"
							value={searchText}
							onChange={handleSearchChange}
							className='block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' />
					</Wrapper>

					<Wrapper>
						{
							loading ? (
								<Loading />
							) : (
								<>
									{
										rows.length > 0
											? (
												<div className='w-full overflow-x-auto'>
													< DataGrid
														rows={filteredRows}
														columns={columns}
														// onRowClick={handleRowClick}
														localeText={esES.components.MuiDataGrid.defaultProps.localeText}
														initialState={{
															pagination: {
																paginationModel: { pageSize: 15, page: savedPageState ? savedPageState.currentPage : 0, },
															},
														}}
														pageSizeOptions={[10, 15, 25]}
														onPaginationModelChange={(model) => handlePageChange(model.page)}
													/>
												</div>
											) : (
												<p className='text-center text-red-500'>No se encontraron resultados.</p>
											)
									}
								</>
							)

						}
					</Wrapper>
				</>

			)}
		</ContainerFull>
	);
}
