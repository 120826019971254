import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectedPreRegister } from '../../redux/actions/preRegistration';
import { optionsCoordinadors, optionsAllAccountsBank } from '../../redux/actions/options';

import { PropertyListItem } from '../../components/PropertyListItem';
import { PropertyItem } from '../../components/PropertyItem';
import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Title } from '../../components/Title';
import { Wrapper } from '../../components/Wrapper';
import { formatDate } from '../../common/formatDateText';
import { capitalizarPalabras, } from '../../common/upperCaseWord';
import { InputText } from '../../components/inputs/InputText';
import { ImageZoom } from '../../components/imageZoom/ImageZoom';

export const DisplayCandidate = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { preRegisterSelected } = useSelector((state) => state.preRegistration);
    const { accountsBank } = useSelector((state) => state.options);

    const [formData, setFormData] = useState({
        idPreregister: id,
        coordinador: '',
        coordinadorName: '',
        createdBy: '',
        account: '',
        accountNumber: '',
        paymentDeadlineDate: null,
        idCourses: '',
        message: '',
        amount: '',
    });

    useEffect(() => {
        dispatch(getSelectedPreRegister(id))
    }, [])

    useEffect(() => {
        dispatch(optionsCoordinadors());
        dispatch(optionsAllAccountsBank());
    }, []);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
            accountNumber: getNumberAccount(),
        }));
    }, [accountsBank])

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
            accountNumber: getNumberAccount(),
            coordinadorName: coordinador ? `${coordinador?.firstName} ${coordinador?.lastName}` : '',
            coordinador: coordinador?._id || '',
            createdBy: coordinador?._id || '',
            amount: amount || '0',
        }));
    }, [preRegisterSelected]);

    const {
        firstName, lastName, email,
        phone, dateBirth, location,
        education, language, status,
        createdAt, account, coordinador,
        urlName, amount
    } = preRegisterSelected;

    const getIdAccount = () => {
        const accountSelected = accountsBank.find((accountBank) => accountBank.value === account);
        return accountSelected?.value;
    }

    const getNumberAccount = () => {
        const accountSelected = accountsBank.find((accountBank) => accountBank.value === account);
        return accountSelected?.description;
    }

    return (
        <ContainerFull>
            <Heading
                title={`Registro de pre registro`}
                subtitle={`Este es el registro de pre registro esta en el estatus de ${status}`}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <Title title='Informacion del cadidato' />
                        <PropertyListItem>
                            <PropertyItem
                                title={`Nombre completo`}
                                description={`${capitalizarPalabras(firstName)} ${capitalizarPalabras(lastName)}`}
                            />
                            <PropertyItem
                                title={`Email`}
                                description={email}
                            />
                            <PropertyItem
                                title={`Telefono`}
                                description={phone}
                            />
                            <PropertyItem
                                title={`Fecha de nacimiento`}
                                description={capitalizarPalabras(formatDate(dateBirth))}
                            />
                            <PropertyItem
                                title={`Ubicacion`}
                                description={capitalizarPalabras(location)}
                            />
                            <PropertyItem
                                title={`Nivel educativo`}
                                description={capitalizarPalabras(education)}
                            />
                            <PropertyItem
                                title={`Estatus del preregistro`}
                                description={capitalizarPalabras(status)}
                            />
                            <PropertyItem
                                title={`Idioma seleccionado por el usuario`}
                                description={capitalizarPalabras(language)}
                            />
                            <PropertyItem
                                title={`Fecha de registro`}
                                description={capitalizarPalabras(formatDate(createdAt))}
                            />
                        </PropertyListItem>
                    </div>
                    {status === 'completado' &&
                        (
                            <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                                <Title title='Comprobante de pago' center={true} />
                                <div className='w-full flex flex-col justify-center items-center'>
                                    <ImageZoom imageUrl={urlName} />
                                </div>
                                <div className='w-[70%] mx-auto flex flex-col mt-8'>
                                    <div className='mb-4'>
                                        <InputText
                                            id={'account'}
                                            name={'account'}
                                            type={'text'}
                                            label={'Cuenta de deposito'}
                                            value={formData.accountNumber}
                                            placeholder={''}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='mb-4'>
                                        <InputText
                                            id={'account'}
                                            name={'account'}
                                            type={'text'}
                                            label={'Coordinador asignado'}
                                            value={capitalizarPalabras(formData.coordinadorName)}
                                            placeholder={''}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='mb-4'>
                                        <InputText
                                            id={'amount'}
                                            name={'amount'}
                                            type={'text'}
                                            label={'Cantidad del deposito'}
                                            value={`$ ${formData.amount}`}
                                            placeholder={''}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </Wrapper>
        </ContainerFull>
    )
}
