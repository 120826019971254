import axios from "../../api/axios";
import {
    cleanSelectedPayment,
    getAllPaymentsStart,
    getAllPaymentsSuccess,
    getAllPaymentsFailure,
    getAllPaymentsByUserIdStart,
    getAllPaymentsByUserIdSuccess,
    getAllPaymentsByUserIdFailure,
    getPaymentByIdStart,
    getPaymentByIdSuccess,
    getPaymentByIdFailure,
    uploadPaymentStart,
    uploadPaymentSuccess,
    uploadPaymentFailure,
    updatePaymentStart,
    updatePaymentSuccess,
    updatePaymentFailure,
    declinePaymentStart,
    declinePaymentSuccess,
    declinePaymentFailure,
    acceptedPaymentFailure,
    acceptedPaymentSuccess,
    acceptedPaymentStart,
} from "../reducers/payment";



export const getAllPayments = ({ startDate, endDate, status, paymentType, accountNumber }) => async (dispatch) => {
    dispatch(getAllPaymentsStart());
    try {
        const response = await axios.get(`/payment/getAllPaymentsWithFilters/${startDate}/${endDate}/${status}/${paymentType}/${accountNumber}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        dispatch(getAllPaymentsSuccess(response.data));
        return response.status;
    } catch (error) {
        dispatch(getAllPaymentsFailure());
    }
};

export const getPaymentById = (paymentId) => async (dispatch) => {
    dispatch(getPaymentByIdStart());
    try {
        const response = await axios.get(`/payment/getPaymentById/${paymentId}`,
            {
                withCredentials: true,
            });
        dispatch(getPaymentByIdSuccess(response.data));
        return response.status;
    } catch (error) {
        dispatch(getPaymentByIdFailure());
    }
};

export const getAllPaymentByUserId = (userId) => async (dispatch) => {
    dispatch(getAllPaymentsByUserIdStart());
    try {
        const response = await axios.get(`/payment/getAllPaymentSubscriptionByUserId/${userId}`,
            {
                withCredentials: true,
            });
        dispatch(getAllPaymentsByUserIdSuccess(response.data));
        return response.status;
    } catch (error) {
        dispatch(getAllPaymentsByUserIdFailure());
    }
};

export const uploadPayment = ({
    amount,
    account,
    userId,
    file,
    invoiceActive
}) => async (dispatch) => {
    dispatch(uploadPaymentStart());
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
        formData.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
        formData.append('upload_preset', 'fca-intranet');

        const res = await axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, formData);


        if (res.status === 200) {
            const response = await axios.post('/payment/createPayment', {
                urlName: res.data.secure_url,
                publicId: res.data.public_id,
                account,
                userId,
                invoiceActive,
                amount: parseFloat(amount),
            });
            if (response.status === 201) {
                dispatch(uploadPaymentSuccess());
                return {
                    status: response.status,
                    message: response.data.message
                };
            }
        }
    } catch (error) {
        dispatch(uploadPaymentFailure());
    }
};

export const updatePayment = ({
    amount,
    account,
    file,
    publicId,
    paymentId,
}) => async (dispatch) => {
    dispatch(updatePaymentStart());
    try {
        if (file !== null) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
            formData.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
            formData.append('upload_preset', 'fca-intranet');

            const res = await axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, formData);


            if (res.status === 200) {
                const response = await axios.put(`/payment/updatePayment/${paymentId}`, {
                    urlName: res.data.secure_url,
                    newPublicId: res.data.public_id,
                    oldPublicId: publicId,
                    account: account._id,
                    amount: parseFloat(amount),
                    changeImage: true
                });
                if (response.status === 200) {
                    dispatch(updatePaymentSuccess());
                    return {
                        status: response.status,
                        message: response.data.message
                    };
                }
            }
        } else {
            const response = await axios.put(`/payment/updatePayment/${paymentId}`, {
                account: account._id,
                amount: parseFloat(amount),
                changeImage: false
            });
            if (response.status === 200) {
                dispatch(updatePaymentSuccess());
                return {
                    status: response.status,
                    message: response.data.message
                };
            }
        }
    } catch (error) {
        dispatch(updatePaymentFailure());
    }
};

export const declinePayment = ({ email, message, paymentId, userId }) => async (dispatch) => {
    dispatch(declinePaymentStart());
    try {
        const response = await axios.put(`/payment/declinePayment/${paymentId}`,
            {
                email: email,
                userId: userId,
                message: message
            });
        if (response.status === 200) {
            dispatch(declinePaymentSuccess());
            return {
                status: response.status,
                message: response.data.message
            };
        }
    } catch (error) {
        dispatch(declinePaymentFailure());
    }
};

export const acceptedPayment = ({ paymentDeadlineDate, email, paymentId, userId, updatedBy, validatedBy }) => async (dispatch) => {
    dispatch(acceptedPaymentStart());
    try {
        const response = await axios.put(`/payment/acceptedPayment/${paymentId}`,
            {
                paymentDeadlineDate: paymentDeadlineDate,
                email: email,
                userId: userId,
                updatedBy: updatedBy,
                validatedBy: validatedBy
            });
        if (response.status === 200) {
            dispatch(acceptedPaymentSuccess());
            return {
                status: response.status,
                message: response.data.message
            };
        }
    } catch (error) {
        dispatch(acceptedPaymentFailure());
    }
};


export const cleanActionSelectedPayment = () => async (dispatch) => {
    dispatch(cleanSelectedPayment());
};

export const checkStatusInvoice = (userId) => async (dispatch) => {
    try {
        const response = await axios.get(`/payment/getUserInvoiceStatus/${userId}`);
        if (response.status === 200) {
            return {
                status: response.status,
                data: {
                    invoiceStatus: response.data.invoiceStatus,
                    invoiceActive: response.data.invoiceActive
                }
            };
        }
    } catch (error) {
        console.log(error);
    }
};
