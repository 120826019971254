import { RadioGroup } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const InputAccountBankMini = ({ accountsBank, accountSelected, setAccountSelected }) => {
    const handleAccount = (value) => {
        if (value === "all") {
            // Aquí puedes manejar la lógica para seleccionar todas las cuentas bancarias
            setAccountSelected((prevData) => ({
                ...prevData,
                accountNumber: { '_id': "allAccountNumbers", 'description': 'allAccountNumbers' }
            }));
        } else {
            const account = accountsBank.find(account => account.value === value);
            setAccountSelected((prevData) => ({
                ...prevData,
                accountNumber: { '_id': account.value, 'description': account.description }
            }));
        }
    };

    return (
        <div>
            {accountsBank && (
                <RadioGroup value={accountSelected?.accountNumber?._id} onChange={(value) => handleAccount(value)}>
                    <RadioGroup.Label className="sr-only">Selecciona una cuenta bancaria</RadioGroup.Label>
                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
                        {/* Opción de seleccionar todas las cuentas */}
                        <RadioGroup.Option
                            key="all"
                            value="all"
                            className={({ active }) =>
                                classNames(
                                    active ? 'ring-2 ring-indigo-500' : '',
                                    'cursor-pointer bg-white text-gray-900 shadow-sm group relative flex items-center justify-center rounded-md border px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                )
                            }
                        >
                            {({ active, checked }) => (
                                <>
                                    <div className="w-full flex min-h-[80px]">
                                        <div className="flex justify-center items-center">
                                            <p className="text-[11px] text-gray-900 font-semibold tracking-wide uppercase">Todas las cuentas de banco</p>
                                        </div>
                                    </div>
                                    <span
                                        className={classNames(
                                            active ? 'border' : 'border-2',
                                            checked ? 'border-indigo-500' : 'border-transparent',
                                            'pointer-events-none absolute -inset-px rounded-md'
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </RadioGroup.Option>

                        {/* Mapear las cuentas bancarias */}
                        {accountsBank.map(({ value, name, description, urlName }) => (
                            <RadioGroup.Option
                                key={value}
                                value={value}
                                className={({ active }) =>
                                    classNames(
                                        active ? 'ring-2 ring-indigo-500' : '',
                                        'cursor-pointer bg-white text-gray-900 shadow-sm group relative flex items-center justify-center rounded-md border px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                    )
                                }
                            >
                                {({ active, checked }) => (
                                    <>
                                        <div className='w-full flex min-h-[100px]'>                                            
                                            <div className='flex justify-center items-center'>
                                                {urlName && (
                                                    <img className='w-[36px]' src={urlName} alt="" />
                                                )}
                                            </div>
                                            <div className='flex flex-col justify-center items-start ml-6'>
                                                <p className='text-[10px] text-gray-600 tracking-wide uppercase'>Nombre de cuenta</p>
                                                <p className='text-[11px] text-gray-900 font-semibold tracking-wide uppercase'>{name}</p>
                                                {description && (
                                                    <div>
                                                        <p className='text-[10px] text-gray-600 tracking-wide uppercase'>Numero de cuenta</p>
                                                        <p className='text-[11px] text-gray-900 font-semibold tracking-wide uppercase'>{description}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <span
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                'pointer-events-none absolute -inset-px rounded-md'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            )}
        </div>
    );
};
