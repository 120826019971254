
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';


export const ImageZoomFull = ({ imageUrl }) => {

	return (
		<Zoom>
			<img
				alt="That Wanaka Tree, New Zealand by Laura Smetsers"
				src={imageUrl}
				width="100%"
                className='rounded-lg'
			/>
		</Zoom>
	);
};
