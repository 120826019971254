import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Title } from '../../components/Title';
import { Wrapper } from '../../components/Wrapper';
import { CardCourseDisplay } from '../courses/components/CardCourseDisplay';

import { toDayDate } from '../../common/formatDateText';
import { ContainerFull } from '../../components/ContainerFull';
import { capitalizarPalabras, firstCapitalLetter } from '../../common/upperCaseWord';


import {
	BriefcaseIcon,
	CalendarIcon,
	MapPinIcon,
} from '@heroicons/react/20/solid'
import { optionsAllCourseListOpenByIdUser } from '../../redux/actions/options';


export const HomePage = () => {
	const dispatch = useDispatch();
	const { coursesListByIdUser } = useSelector((state) => state.options);
	const { user } = useSelector((state) => state.user)
	const { _id: userId, firstName, lastName, typeUser, location } = user;

	useEffect(() => {
		dispatch(optionsAllCourseListOpenByIdUser(userId));
	}, [userId, dispatch]);


	return (
		<ContainerFull>
			<div className="w-full py-6">
				<div className="lg:flex lg:items-center lg:justify-between">
					<div className="min-w-0 flex-1">
						<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
							{`Bienvenido ${firstCapitalLetter(firstName)} ${firstCapitalLetter(lastName)}`}
						</h2>
						<div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
							<div className="mt-2 flex items-center text-sm text-gray-500">
								<BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
								{firstCapitalLetter(typeUser)}
							</div>
							<div className="mt-2 flex items-center text-sm text-gray-500">
								<MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
								{capitalizarPalabras(location)}
							</div>
							<div className="mt-2 flex items-center text-sm text-gray-500">
								<CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
								{capitalizarPalabras(toDayDate())}
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				(typeUser === 'estudiante' || typeUser === 'profesor') && (
					<div className='w-full lg:w-[30%]'>
						<Wrapper>
							<div className='w-full flex flex-col lg:flex-row gap-4'>
								<div className='w-full border-[1px] border-gray-200 rounded-lg p-4'>
									<Title title={'Mis cursos'} />
									<div className="w-full mx-auto">
										{
											coursesListByIdUser.length
												? coursesListByIdUser.map(({ id, headerImage, language, path, color, level, status, teacher, fromDate, toDate, days, hours }) => (
													<CardCourseDisplay
														key={id}
														id={id}
														headerImage={headerImage}
														language={language}
														path={path}
														color={color}
														level={level}
														status={status}
														teacher={teacher}
														fromDate={fromDate}
														toDate={toDate}
														days={days}
														hours={hours}
														action={true}
													/>
												))
												: (
													<p className='text-gray-400'>No hay historial de cursos.</p>
												)
										}
									</div>
								</div>
							</div>
						</Wrapper>
					</div>
				)
			}
		</ContainerFull>
	)
}
