import React, { useState } from 'react'
import { Heading } from '../../components/Heading'
import { DrawerCustom } from '../../components/drawer/Drawer'
import { InputDateWithIcon } from '../../components/inputDateWithIcon/InputDateWithIcon';

export const DrawerFilterCandidates = ({
    isOpenDrawerFilters,
    toggleDrawerFilters,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleRemoveFilter,
    handleApllyFilter
}) => {
    return (
        <DrawerCustom
            isOpen={isOpenDrawerFilters}
            toggleDrawer={toggleDrawerFilters}
            direction='left'
        >
            <div className='flex flex-col h-screen overflow-auto w-full'>
                <div className='flex flex-1 flex-col w-full p-4'>
                    {/* headerr */}
                    <div className='w-full'>
                        <Heading
                            title={"Filtros de la tabla de candidatos"}
                            subtitle={"Selecciona los filtros aplicar para tabla de candidatos."}
                            center={false}
                        />
                    </div>
                    {/* body */}
                    <div className='w-full mt-8 p-2'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div className='w-full'>
                                <InputDateWithIcon
                                    id={'startDay'}
                                    onChange={setStartDate}
                                    value={startDate}
                                    label={'Fecha de inicio'}
                                    placeHolder={'Fecha de inicio'}

                                />
                            </div>
                            <div className='w-full'>

                                <InputDateWithIcon
                                    id={'endDay'}
                                    onChange={setEndDate}
                                    value={endDate}
                                    label={'Fecha de final'}
                                    placeHolder={'Fecha de final'}

                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* footer */}
                <div className='flex w-full justify-end p-4 gap-6 border-t-[.5px] border-indigo-600/10'>
                    <button
                        type='button'
                        onClick={() => handleRemoveFilter()}
                        className='rounded-md font-semibold text-md text-gray-600 shadow-sm shadow-gray-200 bg-gray-200 hover:bg-gray-300 hover:shadow-gray-300 px-4 py-2.5'
                    >{"Borrar Filtros"}</button>
                    <button
                        type='button'
                        onClick={() => toggleDrawerFilters()}
                        className='rounded-md font-semibold text-md text-white shadow-sm shadow-red-500 bg-red-500 hover:bg-red-700 hover:shadow-red-700 px-4 py-2.5'
                    >{"Cancelar"}</button>
                    <button
                        type='button'
                        onClick={() => handleApllyFilter()}
                        className='rounded-md font-semibold text-md text-white shadow-sm shadow-indigo-600 bg-indigo-600 hover:bg-indigo-800 hover:shadow-indigo-800 px-4 py-2.5'
                    >{"Aplicar Filtros"}</button>
                </div>
            </div>
        </DrawerCustom>
    )
}
