
export const formatDate = (inputDate) => {
	if (!inputDate) {
		return "";
	}
	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};

	const date = new Date(inputDate);
	return date.toLocaleDateString('es-ES', options);
}

export const formatDateTime = (inputDate) => {
    if (!inputDate) {
        return "";
    }
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric', // Puedes eliminar esta línea si no necesitas los segundos
        hour12: false, // Usa false para formato 24 horas, true para 12 horas
    };

    const date = new Date(inputDate);
    return date.toLocaleString('es-ES', options);
}

export const toDayDate = () => {
	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	const date = new Date();
	return date.toLocaleDateString('es-ES', options);
}


export const validateSubscription = (date) => {
	// Verifica si la cadena de fecha está vacía
	if (!date) {
		return false;
	}

	// Intenta crear un objeto Date con la cadena de fecha y establece la zona horaria a la Ciudad de México
	const inputDate = new Date(date);
	inputDate.setUTCHours(inputDate.getUTCHours() - 6); // Ajusta la zona horaria a GMT-6 (Ciudad de México)

	// Verifica si la fecha ingresada es válida
	if (isNaN(inputDate.getTime())) {
		return "La fecha no es válida.";
	}

	// Obtén la fecha actual en la zona horaria de la Ciudad de México
	const toDay = new Date();
	toDay.setUTCHours(toDay.getUTCHours() - 6); // Ajusta la zona horaria a GMT-6 (Ciudad de México)

	// Compara las fechas
	return inputDate >= toDay;
};

export const isDateGreaterThanToday = (date) => {
	// Create a Date object with the current date
	const currentDate = new Date();

	// Create a Date object with the provided date
	const enteredDate = new Date(date);

	// Compare the dates
	return enteredDate > currentDate;
}

export const isDateGreaterThanTarget = (date, targetDate) => {
	if (!targetDate) {
		const currentDate = new Date();
	}
	// Create a Date object with the provided date
	const currentDate = new Date(targetDate);
	const enteredDate = new Date(date);

	// Compare the dates
	return enteredDate > currentDate;
}