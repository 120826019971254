import axios from '../../api/axios';

export const verifyStateApp = () => async (dispatch) => {
	try {
		const response = await axios.put(`/stateApp/updateStateAppByVersion`,
			{ version: '1.00.00' }
		);
		return response.data;

	} catch (error) {
		console.log(error);
	}
};