import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-hot-toast';

import { Modal } from '../../components/modal/Modal';
import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { ComboBox } from '../../components/comboBox/ComboBox';

import { optionsCoordinadors } from '../../redux/actions/options';
import { updatePreRegistration } from '../../redux/actions/preRegistration';

export const ModalChangeCoordinator = ({ open, setOpen, preRegisterId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cancelButtonRef = useRef(null);
    const { user } = useSelector((state) => state.user);
    const { preRegisterSelected } = useSelector((state) => state.preRegistration);
    const { coordinadors } = useSelector((state) => state.options);
    
    const { _id: userId } = user;

    const [findCoordinador, setFindCoordinador] = useState('');
    const [loadingChangeCoodinator, setLoadingChangeCoordinator] = useState(false);
    const [formData, setFormData] = useState({
        preRegisterId: preRegisterId,
        updatedBy: '',
        publicId: '',
        oldCoordinator: '',
        newCoordinator: '',
    });

    useEffect(() => {
        dispatch(optionsCoordinadors());
    }, []);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            oldCoordinator: preRegisterSelected?.coordinador?._id || '',
        }));
    }, [preRegisterSelected]);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            updatedBy: '',
            publicId: '',
            newCoordinator: '',
        }));
        setLoadingChangeCoordinator(false);
    }, [open]);

    const filteredCoordinadors = findCoordinador === ''
        ? coordinadors
        : coordinadors.filter((coordinador) =>
            coordinador.description
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findCoordinador.toLowerCase().replace(/\s+/g, ''))
        );

        const handleUpdateCoordinador = async () => {
            if (formData.newCoordinator === '' || formData.newCoordinator === null) {
                toast.error('Selecciona un nuevo coordinador para el candidato');
                return;
            }
            if (formData.newCoordinator.value === formData.oldCoordinator) {
                toast.error('El coordinador seleccionado es el mismo que el actual');
                return;
            }
            const { newCoordinator, idPreregister } = formData;
            setLoadingChangeCoordinator(true);
            dispatch(updatePreRegistration({coordinadorId: newCoordinator.value, preRegisterId: formData.preRegisterId, userId}))
                .then((result) => {
                    if (result.status === 200) {
                        toast.success(result.message);
                        navigate('/candidatos');
                    } else {
                        toast.error(result.message);
                    }
                    setLoadingChangeCoordinator(false);
                });
    
        }

    return (
        <Modal open={open} setOpen={setOpen} cancelButtonRef={cancelButtonRef}>
            <div className="bg-white p-4">
                <div className="flex flex-col">

                    <Dialog.Title as="h3" className="text-center text-lg font-semibold leading-6 text-gray-950">
                        Modificar coordinador
                    </Dialog.Title>
                    <div className="mt-6">
                        <p className="text-sm text-center text-gray-600">
                            Seleciona el nuevo coordinador para el candidato. El nuevo coordinador recibira un correo de notificacion
                        </p>
                    </div>
                    <div className='w-[90%] mx-auto grid grid-cols-1 gap-4 m-8'>
                        <h3 className="text-md font-semibold text-gray-900 mt-4">Seleciona al nuevo coordinador</h3>
                        <ComboBox
                            filterData={filteredCoordinadors}
                            query={findCoordinador}
                            setQuery={setFindCoordinador}
                            selected={formData}
                            setSelected={setFormData}
                            placeholder='Selecione a tu coordinador'
                            property='newCoordinator'
                        />
                    </div>
                    <div className='w-[90%] mx-auto grid grid-cols-1 gap-4 m-8'>
                        <div className='mt-[20px]'>
                            <button
                                type='button'
                                disabled={loadingChangeCoodinator}
                                className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 w-full'
                                onClick={() => handleUpdateCoordinador()}
                            >
                                {loadingChangeCoodinator
                                    ? <ButtonLoader />
                                    : 'Confirmar nuevo coordinador'
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
