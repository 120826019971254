import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { Title } from "../../components/Title";
import { Wrapper } from "../../components/Wrapper";
import { Heading } from "../../components/Heading";
import { Button } from "../../components/buttons/Button";
import { PaymentHistory } from "../payments/PaymentHistory";
import { PropertyItem } from "../../components/PropertyItem";
import { LoaderSection } from "../../components/LoaderSection";
import { ContainerFull } from "../../components/ContainerFull";
import { PropertyListItem } from "../../components/PropertyListItem";

import { getUserById } from "../../redux/actions/users";
import { CardCourseDisplay } from "../courses/components/CardCourseDisplay";
import { optionsAllCourseListOpenByIdUser } from "../../redux/actions/options";
import { cleanActionSelectedPayment, getAllPaymentByUserId } from "../../redux/actions/payment";

import { formatDate } from "../../common/formatDateText";
import { capitalizarPalabras, firstCapitalLetter } from "../../common/upperCaseWord";


export const ProfilePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [canUploadNewPayment, setCanUploadNewPayment] = useState(false)
    const { userSelected } = useSelector((state) => state.users);
    const { paymentsByUser } = useSelector((state) => state.payment);
    const { coursesListByIdUser } = useSelector((state) => state.options);
    const { user } = useSelector((state) => state.user);
    const { _id: userId } = user;

    useEffect(() => {
        dispatch(cleanActionSelectedPayment())
    }, [dispatch])

    useEffect(() => {
        dispatch(getUserById(userId))
    }, [userId, dispatch])

    useEffect(() => {
        dispatch(getAllPaymentByUserId(userId))
    }, [userId, dispatch])

    useEffect(() => {
        dispatch(optionsAllCourseListOpenByIdUser(userId));
    }, [userId, dispatch]);

    useEffect(() => {
        if (paymentsByUser.length) {
            const checkPayments = paymentsByUser.some(item => item.status !== 'validado');
            setCanUploadNewPayment(!checkPayments)
        } else {
            setCanUploadNewPayment(true)
        }
    }, [paymentsByUser])

    const handleNavigateEditProfile = () => {
        navigate(`/editar-perfil/${userId}`)
    }

    const {
        firstName, secondName, lastName, 
        secondSurname, email,
        phone, dateBirth, location,
        education, paymentDeadlineDate, subscriptionPayment,
        matricula, typeUser, emailTax, rfc, businessName, taxRegime
    } = userSelected;

    return (
        <ContainerFull>
            <Heading
                title={`Perfil de usuario`}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        {userSelected ? (
                            <>
                                <Title title={'Datos personales'} />
                                <PropertyListItem>
                                    <PropertyItem
                                        title={`Matricula`}
                                        description={matricula}
                                    />
                                    <PropertyItem
                                        title={`Nombre completo`}
                                        description={`${firstCapitalLetter(firstName)} ${secondName ? firstCapitalLetter(secondName) : ''} ${firstCapitalLetter(lastName)} ${secondName ? firstCapitalLetter(secondSurname) : ''}`}
                                    />
                                    <PropertyItem
                                        title={`Correo electrónico`}
                                        description={email}
                                    />
                                    <PropertyItem
                                        title={`Telefono`}
                                        description={phone}
                                    />
                                    <PropertyItem
                                        title={`Fecha de nacieminto`}
                                        description={capitalizarPalabras(formatDate(dateBirth))}
                                    />
                                    {
                                        typeUser !== 'estudiante' && (
                                            <PropertyItem
                                                title={`Tipo de usuario`}
                                                description={firstCapitalLetter(typeUser)}
                                            />
                                        )
                                    }
                                    {
                                        typeUser === 'estudiante' && (
                                            <PropertyItem
                                                title={`Nivel de educacion`}
                                                description={education}
                                            />
                                        )
                                    }
                                    {
                                        typeUser === 'estudiante' && (
                                            <PropertyItem
                                                title={`Monto de la suscripcion`}
                                                description={subscriptionPayment}
                                            />
                                        )
                                    }
                                    <PropertyItem
                                        title={`Lugar de residencia`}
                                        description={capitalizarPalabras(location)}
                                    />
                                    {
                                        typeUser === 'estudiante' && (
                                            <PropertyItem
                                                title={`Proximo fecha de pago`}
                                                description={capitalizarPalabras(formatDate(paymentDeadlineDate))}
                                            />
                                        )
                                    }
                                </PropertyListItem>
                            </>
                        )
                            : (
                                <LoaderSection />
                            )
                        }

                        {/* {
                            typeUser === 'estudiante' && ( */}
                                <div className="mt-10">
                                    <Title title={'Datos fiscales'} />
                                    <PropertyListItem>
                                        <PropertyItem
                                            title={`Correo electrónico fiscal`}
                                            description={emailTax}
                                        />
                                        <PropertyItem
                                            title={`RFC`}
                                            description={rfc ? rfc.toUpperCase() : ''}
                                        />
                                        <PropertyItem
                                            title={`Razon social`}
                                            description={businessName}
                                        />
                                        <PropertyItem
                                            title={`Regimen fiscal`}
                                            description={taxRegime}
                                        />
                                    </PropertyListItem>
                                </div>
                            {/* )
                        } */}



                        <div className='w-full flex flex-col lg:flex-row justify-end gap-4 mt-4 lg:mt-20'>
                            <button
                                onClick={() => handleNavigateEditProfile()}
                                type='button'
                                className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 hover:bg-indigo-700 w-[100%] lg:w-[25%]'
                            >Editar perfil</button>
                        </div>
                    </div>
                    {
                        typeUser === 'estudiante' && (
                            <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                                <Title title={'Mi historial de pagos'} />

                                <div className='w-full flex justify-end'>
                                    {
                                        canUploadNewPayment && (
                                            <Button
                                                label={"Subir comprobante"}
                                                onClick={() => navigate('/actulizar-suscripcion')}
                                            />
                                        )
                                    }
                                </div>
                                <div className="w-full h-[600px] bg-indigo-900/5 rounded-md mt-4 overflow-auto p-4">
                                    <PaymentHistory action={true} />
                                </div>
                            </div>
                        )}
                </div>
            </Wrapper>
            {
                (typeUser === 'estudiante' || typeUser === 'profesor') && (
                    <div className='w-full lg:w-[40%]'>

                        <Wrapper>
                            <div className='w-full flex flex-col lg:flex-row gap-4'>
                                <div className='w-full border-[1px] border-gray-200 rounded-lg p-4'>
                                    <Title title={'Mis cursos'} />
                                    <div className="w-full mx-auto">
                                        {
                                            coursesListByIdUser.length
                                                ? coursesListByIdUser.map(({ id, headerImage, language, path, color, level, status, teacher, fromDate, toDate, days, hours }) => (
                                                    <CardCourseDisplay
                                                        key={id}
                                                        id={id}
                                                        headerImage={headerImage}
                                                        language={language}
                                                        path={path}
                                                        color={color}
                                                        level={level}
                                                        status={status}
                                                        teacher={teacher}
                                                        fromDate={fromDate}
                                                        toDate={toDate}
                                                        days={days}
                                                        hours={hours}
                                                        action={true}
                                                    />
                                                ))
                                                : (
                                                    <p className='text-gray-400'>No hay historial de cursos.</p>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                )
            }
        </ContainerFull>
    )
}
