import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-hot-toast';

import { Modal } from '../../components/modal/Modal';
import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { InputTextArea } from '../../components/inputTextArea/InputTextArea';
import { rejectPreRegistration } from '../../redux/actions/preRegistration';

export const ModalCancelPreRegister = ({ open, setOpen, preRegisterId }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cancelButtonRef = useRef(null);
	const { preRegisterSelected } = useSelector((state) => state.preRegistration);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		preRegisterId: preRegisterId,
        email: '',
		publicId: '',
        message: ''
	});
	
	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			message: ''
		}));
		setLoading(false);
	}, [open]);
	
	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			publicId: preRegisterSelected?.publicId || '',
			email: preRegisterSelected?.email || ''
		}));
		setLoading(false);
	}, [preRegisterSelected]);
		
	const onChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	}

	const validateForm = () => {
		if (formData.message === '') {
			toast.error('Ingresa un mensaje para el candidato.');
			return false;
		}
		return true;	
	}

	const handleCancelPreRegister = async () => {
		const valid = validateForm();
		if (valid) {
			setLoading(true);
			dispatch(rejectPreRegistration(formData))
				.then((result) => {
					if (result.status === 200) {
						setOpen(false);
						setFormData((prevData) => ({
							...prevData,
							message: ''
						}));
						setLoading(false);
						navigate('/candidatos');
					} else {
						toast.error(result.message);
						setOpen(false);
						setLoading(false);
					}
				});
		}

	}

	return (
		<Modal open={open} setOpen={setOpen} cancelButtonRef={cancelButtonRef}>
			<div className="bg-white p-4">
				<div className="flex flex-col">

					<Dialog.Title as="h3" className="text-center text-lg font-semibold leading-6 text-gray-950">
						Cancelar solicitud de registro
					</Dialog.Title>
					<div className="mt-6">
						<p className="text-sm text-center text-gray-600">
							Explicale al estudiante por que estas cancelando su solicitud de registro. Este mensaje sera enviado al estudiante mediante correo electronico.
						</p>

					</div>
					<div className='w-[90%] mx-auto grid grid-cols-1 gap-4 m-8'>
						<div>
							<InputTextArea
								id={'message'}
								name={'message'}
								type={'message'}
								label={'Explique al estudiante por que esta cancelando su solicitud.'}
								onChange={(e) => onChange(e)}
								value={formData.message}
								placeholder={'Ingresa tu mensaje...'}
							/>
						</div>
						<div className='mt-[20px]'>
							<button
								type='button'
								disabled={loading}
								className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 w-full'
								onClick={() => handleCancelPreRegister()}
							>
								{loading
									? <ButtonLoader />
									: 'Enviar mensaje al candidato'
								}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
