export const LoaderSection = () => {
    return (
        <>
            <div className="flex justify-center items-center bg-white/25">
                <div className="relative inline-flex">
                    <div className="w-16 h-16 bg-indigo-500 rounded-full"></div>
                    <div className="w-16 h-16 bg-indigo-600 rounded-full absolute top-0 left-0 animate-ping"></div>
                    <div className="w-16 h-16 bg-indigo-600 rounded-full absolute top-0 left-0 animate-pulse"></div>
                </div>
            </div>
        </>
    )
}
